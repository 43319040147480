/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useEffect, useState, useContext } from 'react';
//import { GetCustomerinfo } from '../../Stub/Stub';
import {
  GetCustomer,
  GetCustomerId,
  getAgreementCustomerInfo,
  GetLegalHold,
  RunDE,
} from '../../../api/user';
//import CustomerInfoProps from './CustomerInfoInterface';
import { Link, useParams, useHistory } from 'react-router-dom';
import { AgreementContext } from '../../../context/AgreementContext';
import { ReactComponent as Alertimage } from '../../../assets/images/no-records-found.svg';
import {
  RACBadge,
  Grid,
  Typography,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
import { wizardStyles } from '../../../JSstyles/wizardStyles';
import { DE_APPROVAL_STATUS, DE_APPROVAL_STATUS_TEXT, getVerificationString } from '../../../constants/constants';

export default function ViewAgrapprovalamntheader(props: any) {
  const history = useHistory();
  const { getCustomerResponse } = useContext(AgreementContext)
  const { featureFlagDetails } = useContext(FeatureFlagContext)
  const { CoCustomerDetails, dereceiver, amountThrower } = props;
  const classes = agreementGlobalStyles();
  const wizStyle = wizardStyles();
  //PS_AD-1 A object declared to store the customer information from database
  const CustomerInfo = {
    CustomerName: '',
    CustomerID: '',
    PhoneNumber: '',
    DEstatus: '',
    ExpiryDate: '',
    CoCustomerName: '',
    CoCustomerId: '',
    Skip: '',
    Stolen: '',
    Hard: '',
  };
  interface Params {
    agreementId: string;
    customerId: string;
  }
  const {
    CustomerInfoContext,
    viewAgreementData,
    viewAgrData,
    ReturnAgrInformation,
    setstoreExpiryDate,
    setentireGetCustomerData,
    approvalAmountHolder,
    getViewAgrData,
  } = useContext(AgreementContext);
  //PS_AD-1 A state variable declared to store the CustomerInfo object

  const [CustomerInformation, setCustomerInformation] = useState(CustomerInfo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CustomerValue, setCustomerValue] = CustomerInfoContext;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cocustomervalue, setcocustomervalue] = CustomerInfoContext;
  const { agreementId, customerId } = useParams<Params>();
  const [CoCustomerName, setCoCustomerName] = useState('');
  const [CoCustomerId, setCoCustomerId] = useState('');
  const [CustomerDEstaus, setCustomerDEstaus] = useState('');
  const [CustomerExpDate, setCustomerExpDate] = useState('');
  const [ApprovalAmount, setApprovalAmount] = useState('');
  const [WeeklyApprovalAmount, setWeeklyApprovalAmount] = useState('');
  const [agrStatus, setagrStatus] = useState(false);
  const [showLevels, setShowLevels] = useState(false)

  // const [successpopup, Setsuccesspopup] = useState(false);
  // const [needDE, setneedDE] = useState(false);
  // const [IscustomerapiFailed, SetIscustomerapiFailed] = useState(false);
  // // const [entireGetCustomerData, setentireGetCustomerData] = useState<any>();
  // const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  // const [postnodeReason, setpostnodeReason] = useState('');
  // const [reasonDEopen, setreasonDEopen] = React.useState(false);
  // const [isLegalHold, setIsLegalHold] = useState(false);
  // const [legalholderror, setlegalholderror] = useState(false);

  // const API_ERROR_MESSAGE = 'Unable to fetch the data';
  // const changeDEStatus = () => {
  //   console.log('Change De Statusfcsackjnsdkjv');

  // };
  // useEffect(() => {
  //   console.log('ngbfvdssreaafawesdvcdsx', dereceiver);
  //   //console.log('receibed cdhvbhd data amount---->', amountThrower);
  //   console.log(
  //     'approvalAmountHolder cdhvbhd data amount------>',
  //     approvalAmountHolder
  //   );

  //   if (dereceiver === true && approvalAmountHolder !== undefined) {
  //     if (approvalAmountHolder.data.approvalResponse == '1') {
  //       setCustomerDEstaus('A');
  //     } else if (approvalAmountHolder.data.approvalResponse == '2') {
  //       setCustomerDEstaus('D');
  //     } else if (
  //       approvalAmountHolder.data.approvalResponse == '4' ||
  //       approvalAmountHolder.data.approvalResponse == '5'
  //     ) {
  //       setCustomerDEstaus('M');
  //     } else if (approvalAmountHolder.data.approvalResponse == '') {
  //       setCustomerDEstaus('');
  //     }
  //     // setCustomerDEstaus('A');
  //     console.log(
  //       'approvalAmountHolder.data.originalApprovalAmount',
  //       approvalAmountHolder.data.approvalAmount
  //     );
  //     console.log(
  //       'approvalAmountHolder.data.weeklyOriginalApprovalAmount',
  //       approvalAmountHolder.data.weeklyApprovalAmount
  //     );

  //     const RawExpDateArr =
  //       approvalAmountHolder.data.approvalExpirationDate !== ''
  //         ? approvalAmountHolder.data.approvalExpirationDate.split('-')
  //         : '';
  //     console.log('RawExpDateArr checker log', RawExpDateArr);
  //     setstoreExpiryDate(approvalAmountHolder.data.approvalExpirationDate);
  //     if (RawExpDateArr !== '') {
  //       const ExpDate =
  //         RawExpDateArr[1] + '/' + RawExpDateArr[2] + '/' + RawExpDateArr[0];
  //       console.log('ExpDate logger update log', ExpDate);

  //       setCustomerExpDate(ExpDate);
  //     }
  //     setApprovalAmount(
  //       Number(approvalAmountHolder.data.approvalAmount).toFixed(2)
  //     );
  //     setWeeklyApprovalAmount(
  //       Number(approvalAmountHolder.data.weeklyApprovalAmount).toFixed(2)
  //     );
  //   }
  // }, [dereceiver]);

  useEffect(() => {
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Inside Bind Name useeffect cocustomer details Name',
        CustomerInformation
      );
      console.log(
        'Inside Bind cocustomer context details Name',
        CustomerValue,
        CustomerInformation
      );
      setCustomerInformation({
        ...CustomerInformation,
        CoCustomerName: CoCustomerDetails.coCustomerName,
        CoCustomerId: CoCustomerDetails.coCustomerId,
      });
    }
  }, [CoCustomerDetails]);

  // const RediectToCustomer = () => {
  //   history.push({
  //     pathname: `/customer/searchcustomer`,
  //   });
  // };
  // const RediectToDashBoard = () => {
  //   history.push({
  //     pathname: `/dashboard/homepage`,
  //   });
  // };

  /* eslint-disable sonarjs/cognitive-complexity */
  useEffect(() => {
    console.log('Customer id in customer info', customerId);
    let skip: '';
    let stolen: '';
    let hard: '';
    const getCustomer_APIcall = async () => {
      const currentStore = sessionStorage.getItem('storeNumber');
      const urlParams = new URLSearchParams(window.location.search);
      const coCustomerIdParams = urlParams.get('coCustomerId');
      if (
        agreementId !== undefined &&
        agreementId !== null &&
        agreementId !== ''
      ) {
        let response;
        console.log('raw viewAgrData log in custinfo', viewAgrData);

        //console.log('viewAgrData.data to check', viewAgrData.data);

        if (viewAgrData == undefined) {
          //.data removed
          console.log('viewAgreementData is  undefined', viewAgrData);

          response = await getAgreementCustomerInfo(agreementId);
          console.log('response to be checked', response);

          //response = response.data;
          console.log('response to be checked drilled', response);
          if (
            response !== undefined &&
            response.data !== undefined &&
            response.data.customer !== undefined &&
            response.data.customer.length > 0
          ) {
            response = response.data;
            const fullname =
              response?.customer[0]?.firstName +
              ' ' +
              response?.customer[0]?.lastName;
            props.receiveCustomerName(fullname);
            props.receiveCustomerID(response?.customer[0]?.customerId);
            const CustomerObj = {
              GlobalCustomerID: response.customer[0].gcdid,
              CustomerName: response.customer[0].customerName,
              CustomerId: response.customer[0].customerId,
              CusotmerPhoneNumber: response.customer[0].phoneNumber,
              CustomerEmail: response.customer[0].emailAddress,
              CoCustomerName: '',
              CoCustomerId: '', // Added
            };
            setCustomerInformation({
              ...CustomerInformation,
              CustomerName: response.customer[0].customerName,
              CustomerID: response.customer[0].customerId,
              PhoneNumber: response.customer[0].phoneNumber,
            });
            console.log('vdvf kjnjenv 644');
            setCustomerValue(CustomerObj);
            if (
              response !== undefined &&
              response.coCustomer !== undefined &&
              response.coCustomer.length > 0
            ) {
              setCustomerInformation({
                ...CustomerInformation,
                CustomerName: response.customer[0].customerName,
                CustomerID: response.customer[0].customerId,
                PhoneNumber: response.customer[0].phoneNumber,
                CoCustomerName: response.coCustomer[0].customerName,
                CoCustomerId: response.coCustomer[0].customerId,
              });
              console.log('Customer Object before', CustomerObj);
              const CustomerObject = {
                GlobalCustomerID: CustomerObj.GlobalCustomerID,
                CustomerName: CustomerObj.CustomerName,
                CustomerId: CustomerObj.CustomerId,
                CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                // CustomerVehicles: CustomerObj.CustomerVehicles,
                CustomerEmail: CustomerObj.CustomerEmail,
                CoCustomerName: response.coCustomer[0].customerName,
                CoCustomerId: response.coCustomer[0].customerId,
              };
              console.log(
                'Customer Object after',
                CustomerObj,
                CustomerObject,
                CustomerValue,
                CustomerInfoContext
              );
              setCoCustomerName(response.coCustomer[0].customerName);
              console.log('cdv5v45vdf5v');

              setcocustomervalue(CoCustomerId);
              setCustomerValue(CustomerObject);
            }

            const getCustomerApprovalPayload = {
              globalCustomerId: response.customer[0].globalCustomerId,
              storeNumber: currentStore,
            };

            console.log(
              'Getcustomer Approval Object',
              getCustomerApprovalPayload
            );

            let customerDetails: any = await GetCustomer(
              customerId,
              getCustomerApprovalPayload
            );
            console.log(
              'Getcustomer Approval Response',
              getCustomerApprovalPayload,
              customerDetails
            );

            if (
              customerDetails !== undefined &&
              customerDetails.data !== undefined &&
              customerDetails.data.customerApprovalDetails !== undefined &&
              customerDetails.data.customerApprovalDetails !== null &&
              customerDetails.data.customerApprovalDetails !== ''
            ) {
              customerDetails = customerDetails.data;
              const DEstatus =
                customerDetails.customerApprovalDetails.approvalStatus;
              const RawExpDate =
                customerDetails.customerApprovalDetails.approvalExpirationDate;
              if (RawExpDate !== undefined) {
                const RawExpDateArr = RawExpDate.split('-');
                const ExpDate =
                  RawExpDateArr[1] +
                  '/' +
                  RawExpDateArr[2] +
                  '/' +
                  RawExpDateArr[0];
                setCustomerExpDate(ExpDate);
              }

              const ApprovalAmt =
                customerDetails?.customerApprovalDetails?.approvalAmount
                  ? customerDetails?.customerApprovalDetails?.approvalAmount
                  : 0;
              const WeeklyApprovalAmt =
                customerDetails?.customerApprovalDetails?.weeklyApprovalAmount
                  ? customerDetails?.customerApprovalDetails?.weeklyApprovalAmount
                  : 0;
              if (DEstatus !== undefined) {
                setCustomerDEstaus(DEstatus);
              }
              setApprovalAmount((Number(ApprovalAmt))?.toFixed(2));
              setWeeklyApprovalAmount((Number(WeeklyApprovalAmt))?.toFixed(2));
            }
          }
        } else {
          console.log('viewAgreementData is not undefined ', viewAgrData);

          response = viewAgrData.data;
          if (
            response !== undefined
            // response.customer !== undefined &&
            // response.customer.length > 0
          ) {
            //response = response.data;

            const fullname =
              response.agreementCustomer[0].firstName +
              ' ' +
              response.agreementCustomer[0].lastName;
            console.log('bvcdxsv', fullname);

            props.receiveCustomerName(fullname);
            props.receiveCustomerID(response.agreementCustomer[0].customerId);

            const CustomerObj = {
              GlobalCustomerID: response.agreementCustomer[0].globalCustomerId,
              CustomerName:
                response.agreementCustomer[0].firstName +
                ' ' +
                response.agreementCustomer[0].lastName,
              CustomerId: response.agreementCustomer[0].customerId,
              CusotmerPhoneNumber: response.agreementCustomer[0].phoneNumber,
              CustomerEmail: response.agreementCustomer[0].emailId,
              CoCustomerName: '',
              CoCustomerId: '', // Added
            };
            setCustomerInformation({
              ...CustomerInformation,
              CustomerName:
                response.agreementCustomer[0].firstName +
                ' ' +
                response.agreementCustomer[0].lastName,
              CustomerID: response.agreementCustomer[0].customerId,
              PhoneNumber: response.agreementCustomer[0].phoneNumber,
            });
            console.log('cdvjnk efw541ef');
            setCustomerValue(CustomerObj);
            if (
              response !== undefined &&
              response.agreementCustomer.length > 1
            ) {
              setCustomerInformation({
                ...CustomerInformation,
                CustomerName:
                  response.agreementCustomer[0].firstName +
                  ' ' +
                  response.agreementCustomer[0].lastName,
                CustomerID: response.agreementCustomer[0].customerId,
                PhoneNumber: response.agreementCustomer[1].phoneNumber,
                CoCustomerName:
                  response.agreementCustomer[1].firstName +
                  ' ' +
                  response.agreementCustomer[1].lastName,
                CoCustomerId: response.agreementCustomer[1].customerId,
              });
              console.log('Customer Object before', CustomerObj);
              const CustomerObject = {
                GlobalCustomerID: CustomerObj.GlobalCustomerID,
                CustomerName: CustomerObj.CustomerName,
                CustomerId: CustomerObj.CustomerId,
                CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                // CustomerVehicles: CustomerObj.CustomerVehicles,
                CustomerEmail: CustomerObj.CustomerEmail,
                CoCustomerName:
                  response.agreementCustomer[1].firstName +
                  ' ' +
                  response.agreementCustomer[1].lastName,
                CoCustomerId: response.agreementCustomer[1].customerId,
              };
              console.log(
                'Customer Object after',
                CustomerObj,
                CustomerObject,
                CustomerValue,
                CustomerInfoContext
              );
              setCoCustomerName(
                response.agreementCustomer[1].firstName +
                ' ' +
                response.agreementCustomer[1].lastName
              );
              console.log('vfvf evjnejr 46848');

              setcocustomervalue(CoCustomerId);
              setCustomerValue(CustomerObject);
            }

            const getCustomerApprovalPayload = {
              globalCustomerId: response.agreementCustomer[0].globalCustomerId,
              storeNumber: currentStore,
            };

            console.log(
              'Getcustomer Approval Object',
              getCustomerApprovalPayload
            );

            let customerDetails: any = await GetCustomer(
              customerId,
              getCustomerApprovalPayload
            );

            console.log(
              'Getcustomer Approval Response',
              getCustomerApprovalPayload,
              customerDetails
            );

            if (
              customerDetails !== undefined &&
              customerDetails.data !== undefined &&
              customerDetails.data.customerApprovalDetails !== undefined &&
              customerDetails.data.customerApprovalDetails !== null &&
              customerDetails.data.customerApprovalDetails !== ''
            ) {

              customerDetails = customerDetails.data;

              const DEstatus =
                customerDetails.customerApprovalDetails.approvalStatus;
              const RawExpDate =
                customerDetails.customerApprovalDetails.approvalExpirationDate;
              if (RawExpDate !== undefined) {
                const RawExpDateArr = RawExpDate.split('-');
                const ExpDate =
                  RawExpDateArr[1] +
                  '/' +
                  RawExpDateArr[2] +
                  '/' +
                  RawExpDateArr[0];
                setCustomerExpDate(ExpDate);
              }

              const ApprovalAmt =
                customerDetails?.customerApprovalDetails?.approvalAmount
                  ? customerDetails?.customerApprovalDetails?.approvalAmount
                  : 0;
              const WeeklyApprovalAmt =
                customerDetails?.customerApprovalDetails?.weeklyApprovalAmount
                  ? customerDetails?.customerApprovalDetails?.weeklyApprovalAmount
                  : 0;
              if (DEstatus !== undefined) {
                setCustomerDEstaus(DEstatus);
              }
              setApprovalAmount((Number(ApprovalAmt))?.toFixed(2));
              setWeeklyApprovalAmount((Number(WeeklyApprovalAmt))?.toFixed(2));
            }
          }
        }
      }
    };
    getCustomer_APIcall();
    statusBadge();
  }, []);
  const tempMethod = () => {
    console.log('Empty Console');
  };
  useEffect(() => {
    statusBadge();
  }, [viewAgrData, ReturnAgrInformation]);
  const statusBadge = () => {
    console.log('statusBadgegetViewAgrData', viewAgrData);
    console.log('ReturnAgrInformation', ReturnAgrInformation);
    if (ReturnAgrInformation == undefined) {
      if (
        viewAgrData?.data?.agreementInformation?.agreementStatus == 'Active'
      ) {
        setagrStatus(true);
      } else if (
        viewAgrData?.data?.agreementInformation?.agreementStatusRefCode ==
        'ARTS'
      )
        setagrStatus(false);
    } else {
      if (
        ReturnAgrInformation?.data?.agreementInformation?.agreementStatus ==
        'Active'
      ) {
        setagrStatus(true);
      } else if (
        ReturnAgrInformation?.data?.agreementInformation
          ?.agreementStatusRefCode == 'ARTS'
      )
        setagrStatus(false);
    }
  };

  // const errorpopup = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           {IscustomerapiFailed === false
  //             ? 'This customer is already a cocustomer on another agreement.'
  //             : 'This Customer is a co-customer'}
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => RediectToCustomer()}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };
  // const handleerror = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           Unable to create agreement due to customer status
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => RediectToDashBoard()}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };

  return (
    <>
      <Grid className={classes.overall}>
        {/* <Grid className={`${classes.floatLeft} ${classes.me3} `}> */}
        {ApprovalAmount !== '' ? (
          <Typography className={classes.agrInfoPanelviewagr}>
            <Typography
              variant="caption"
              className={wizStyle.approvalAmtNotCursor}
            >
              Approval Amount :
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.racpadLinkNotCursor} ${classes.check}`}
            >
              {'$' + ApprovalAmount}
            </Typography>
          </Typography>
        ) : null}
        {WeeklyApprovalAmount !== '' ? (
          <Typography className={classes.agrInfoPanelviewagr}>
            <Typography
              variant="caption"
              className={wizStyle.approvalAmtNotCursor}
            >
              Weekly Approval Amount :
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.racpadLinkNotCursor} ${classes.check}`}
            >
              {' '}
              {'$' + WeeklyApprovalAmount}
            </Typography>
          </Typography>
        ) : null}
        {/* </Grid> */}
      </Grid>

      {/* Verification Optimization start */}

      {featureFlagDetails?.CustomerVerificationOptimization == 1 ? <>

        {getCustomerResponse && getCustomerResponse?.GetCustomer?.value && getCustomerResponse?.GetApproval?.value?.customerApprovalDetails && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '0' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? <>
          <span
            style={{
              backgroundColor: '#bb37ae',
              color: 'white',
              borderRadius: '11px',
              marginRight: '13px',
              float: 'left',
              padding: '4px 10px',
              fontSize: '0.75em',
              fontWeight: 700,
              lineHeight: 1,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              verticalAlign: 'baseline',
              marginTop: '7px'
            }}
          >
            {getCustomerResponse?.GetCustomer?.value?.salesLeadId && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? 'Previous-Web' : getCustomerResponse?.GetCustomer?.value?.salesLeadId && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '0' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '1') ? 'New-Web' : (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == '-1' || getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyCode == null) ? 'Previous' : 'New'}
          </span>
        </> : null}
        {/* Customer type binding end */}

        {/* Verification Level badge start*/}
        {getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == 'COMPLETED') ? <span
          style={{
            backgroundColor: '#0d6efd',
            color: 'white',
            borderRadius: '11px',
            marginRight: '13px',
            float: 'left',
            padding: '4px 10px',
            fontSize: '0.75em',
            fontWeight: 700,
            lineHeight: 1,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            marginTop: '7px'
          }}
        >
          {'Verified'}
        </span> : getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == 'PENDING' ?
          <span style={{ position: 'relative' }}>
            <span
              onMouseOver={() => {
                setShowLevels(true);
              }}
              onMouseOut={() => {
                setShowLevels(false);
              }}
              style={{
                backgroundColor: '#e85c5c',
                color: 'white',
                borderRadius: '11px',
                marginRight: '13px',
                float: 'left',
                padding: '4px 10px',
                fontSize: '0.75em',
                fontWeight: 700,
                lineHeight: 1,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                marginTop: '7px',

                cursor: 'pointer',
              }}
            >
              {getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel && getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus != 'COMPLETED' ? `Level - ${getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel.replace('-1', '3')} ` : 'Verified'}
            </span>
            {showLevels && (
              <div style={{
                position: 'absolute',
                bottom: '0px',
                right: 0,
                transform: 'translateX(-50%)',
                marginBottom: '5px',
                backgroundColor: '#e9f1f6',
                color: 'black',
                padding: '5px',
                borderRadius: '10px',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                zIndex: 2000,
                fontWeight: 'bold'
              }}>
                {getCustomerResponse?.GetApproval?.value
                  ?.customerApprovalDetails ? getVerificationString(getCustomerResponse?.GetApproval?.value
                    ?.customerApprovalDetails) : ''}
              </div>
            )}
          </span> : getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verifyLevel && (getCustomerResponse?.GetApproval?.value?.customerApprovalDetails?.verificationStatus == 'REVIEW PENDING') ? <span
            style={{
              backgroundColor: 'rgb(253 180 13)',
              color: 'white',
              borderRadius: '11px',
              marginRight: '5px',
              float: 'left',
              padding: '5px 10px',
              fontSize: '0.75em',
              fontWeight: 700,
              lineHeight: 1,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              verticalAlign: 'baseline',
              marginTop: '7px'
            }}
          >
            {'Review Pending'}
          </span> : null}

        {/* Verification Level badge end */}

      </> : null}

      {/* Verification Optimization end */}
      <div style={{ float: 'left' }}>
        <div
          className={`${classes.floatLeft} ${classes.me2} ${classes.textCenter} ${classes.mt2} ${classes.floatflex}`}
        >
          {CustomerDEstaus === DE_APPROVAL_STATUS.APPROVED ? (
            <RACBadge
              backgroundColor="#5AE286"
              color="#fff"
              OnclickNeed={false}
              TextValue={DE_APPROVAL_STATUS_TEXT.APPROVED}
              handleOnClick={tempMethod}
            />
          ) : null}
          {CustomerDEstaus === DE_APPROVAL_STATUS.DECLINED ? (
            <RACBadge
              backgroundColor="#FF0000"
              color="#fff"
              OnclickNeed={false}
              TextValue={DE_APPROVAL_STATUS_TEXT.DECLINED}
              handleOnClick={tempMethod}
            />
          ) : null}
          {CustomerDEstaus === DE_APPROVAL_STATUS.CONDITIONAL_APPROVAL ? (
            <RACBadge
              backgroundColor="#198754"
              color="#fff"
              OnclickNeed={false}
              TextValue={DE_APPROVAL_STATUS_TEXT.CONDITIONAL_APPROVAL}
              handleOnClick={tempMethod}
            />
          ) : null}
          {CustomerDEstaus === '' ? (
            <RACBadge
              backgroundColor="#FF0000"
              color="#fff"
              OnclickNeed={false}
              TextValue={DE_APPROVAL_STATUS_TEXT.NO_DE}
              handleOnClick={tempMethod}
            />
          ) : null}
          {CustomerDEstaus === DE_APPROVAL_STATUS.EXPIRED ? (
            <RACBadge
              backgroundColor="#FF0000"
              color="#fff"
              OnclickNeed={false}
              TextValue={DE_APPROVAL_STATUS_TEXT.EXPIRED}
              handleOnClick={tempMethod}
            />
          ) : null}
          <div style={{ float: 'right' }}>
            {
              <RACBadge
                backgroundColor={agrStatus ? '#66BB55' : '#FF0000'}
                color="#fff"
                OnclickNeed={false}
                TextValue={agrStatus ? 'Active' : 'InActive'}
                handleOnClick={tempMethod}
              />
            }
          </div>
        </div>
        {CustomerExpDate !== '' && CustomerDEstaus !== 'D' ? (
          <Typography variant="caption" className={wizStyle.expDate}>
            Expires on:{CustomerExpDate}
          </Typography>
        ) : null}
      </div>
      {/* </Grid> */}
      {/* <RACModalCard
        isOpen={successpopup}
        maxWidth="xs"
        // title="Modal title"
        onClose={() => RediectToCustomer()}
        // eslint-disable-next-line react/no-children-prop
        children={errorpopup()}
        borderRadius="20px"
      /> */}
      {/* <RACModalCard
        isOpen={legalholderror}
        maxWidth="xs"
        // title="Modal title"
        onClose={() => RediectToDashBoard()}
        // eslint-disable-next-line react/no-children-prop
        children={handleerror()}
        borderRadius="20px"
      /> */}
    </>
  );
}
