/* eslint-disable */
export const ProductType = 'RPDAGR';

export enum ProductPermissions {
  AGREEMENT = 'AGREEMENT',
}

export const AMOrigin = 'am-customer';
export const AccountManagementURLParam = `?origin=${AMOrigin}`;
export const SACrolesArray = [
  "FSC-CAR",
  "CAR",
  "RPMT",
  "FSC-AM",
  "AM",
  "FSC-LAM",
  "LAM",
  "FSC-SMT",
  "SMT",
  "FSC-SM",
  "SM",
  "FSC-SSM",
  "SSM",
  "CAM",
  "FSC-DMT",
  "DMT",
  "FSC-DM",
  "DM",
  "FSC-RDT",
  "RDT",
  "FSC-RD",
  "RD"
];
export const ADD_ON_PACKAGE = 'Add-On Package'
export const ADD_ON_PRICING = 'AddOnPricing'

export const getPrimaryPhoneNumber = (phoneDetails: any) => {
  console.log('initial execution', phoneDetails)
  const activePhoneNumber: any = []
  const primaryPhoneNumber: any = []
  for (let phoneNumber = 0; phoneNumber < phoneDetails.length; phoneNumber++) {
    if (phoneDetails[phoneNumber]?.primary == 'Y') {
      primaryPhoneNumber.push(phoneDetails[phoneNumber])
      break;
    }
    if (phoneDetails[phoneNumber]?.active == 'Y' && activePhoneNumber.length == 0) {
      activePhoneNumber.push(phoneDetails[phoneNumber])
    }
  }
  console.log('primaryPhoneNumber', primaryPhoneNumber, activePhoneNumber)
  return primaryPhoneNumber.length > 0 ? primaryPhoneNumber : activePhoneNumber
}

export enum DE_APPROVAL_STATUS_TEXT {
  APPROVED = 'Approved',
  CONDITIONAL_APPROVAL = 'Approved',
  DECLINED = 'Declined',
  EXPIRED = 'DE Expired',
  NO_DE = 'No DE',
}

export enum DE_APPROVAL_STATUS {
  APPROVED = 'A',
  CONDITIONAL_APPROVAL = 'M',
  DECLINED = 'D',
  EXPIRED = 'E',
}

export const CARRIED_RENT_ERROR_MESSAGE = 'All carried rent must be collected before any agreements can be split/switched.';

export function getVerificationString(customerApprovalDetails: any) {
  if (!customerApprovalDetails || !customerApprovalDetails.verificationDocuments) {
    return '';
  }

  const requiredVerifications = customerApprovalDetails.verificationDocuments
    .filter((doc: any) => doc.verifyRequired === 'Y' && doc.verifyType !== 'PHONE')
    .map((doc: any) => {
      switch (doc.verifyType) {
        case 'ID':
          return 'Identity';
        case 'RESIDENCE':
          return 'Residence';
        case 'EMPLOYMENT':
          return 'Employment';
        case 'REFERENCES':
          return 'Reference';
        default:
          return '';
      }
    })
    .filter((verif: any) => verif); // Filter out empty strings

  if (requiredVerifications.length === 0) {
    return 'No verification required';
  }

  let verificationString;
  if (requiredVerifications.length === 1) {
    verificationString = requiredVerifications[0];
  } else {
    verificationString = requiredVerifications.slice(0, -1).join(', ') + ', and ' + requiredVerifications.slice(-1);
  }

  return `${verificationString} Verification Required`;
}
export const level4customerSignModuleName = 'LevelFourEmailSignAccess'


