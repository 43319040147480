/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//PS_SO-GI-1
import React from 'react';
import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACSelect,
  RACDatePicker,
  RACButton,
} from '@rentacenter/racstrap';
import { agrGeneralInfoStyles } from '../../../JSstyles/agrGeneralInfoStyles';
import { getSwitchOutAgreementOptions } from '../../../api/user';
import { useParams } from 'react-router-dom';

interface Params {
  customerId: string;
  agreementId: string;
}
export default function SwitchOutGeneralInformation(props: any) {
  const { customerId, agreementId } = useParams<Params>();
  //PS_SO-GI-2
  const classes = props.throwStyle;
  //PS_SO-GI-3
  const agrGeneralInfoClassName = agrGeneralInfoStyles();
  //PS_SO-GI-4
  const payScheduleObj = {
    payschedulePeriod: '',
    payscheduleDay: '',
  };
  //PS_SO-GI-5
  const dropdownOptionsObj = {
    agreementSource: [],
    schedule: [],
    deliveryCharge: [],
    daysExtension: [],
    promoCode: [],
    optServices: [],
    range: [],
  };
  //PS_SO-GI-6
  const dropdownValuesObj = {
    agreementSource: '',
    schedule: '',
    deliveryCharge: '',
    dayExtension: '',
    optionalServices: '',
    salesPerson: '',
    range: '',
    promoCode: '',
  };
  //PS_SO-GI-7
  const {
    setnextBtnDisabled,
    generalInfoValue,
    salesPersonDrpdwnVal,
    editDisableMode,
    generalInfoChanged,
    pendingSOAgr,
  } = props;
  //PS_SO-GI-8
  const [dropdownOptions, setdropdownOptions] = useState(dropdownOptionsObj);
  //PS_SO-GI-9
  const [dropdownValues, setdropdownValues] = useState(dropdownValuesObj);
  //PS_SO-GI-10
  const [dropDownLoading, setdropDownLoading] = useState(true);
  //PS_SO-GI-11
  const [dropDownDisable, setdropDownDisable] = useState(editDisableMode);
  //PS_SO-GI-12
  const [salesDropdownDisabled, setsalesDropdownDisabled] = useState(false);
  //PS_SO-GI-13
  const [salesDropdownOptions, setsalesDropdownOptions]: any = useState([]);
  //PS_SO-GI-14
  const [payschedule, setpayschedule] = useState(payScheduleObj);
  //PS_SO-GI-15
  const [dueDateValue, setdueDateValue] = useState('');
  //PS_SO-GI-16
  const [currentDate, setcurrentDate] = useState('');
  //PS_SO-GI-17
  const [maxDate, setmaxDate] = useState('');
  //PS_SO-GI-18
  const [termValue, settermValue] = useState('');
  //PS_SO-GI-19
  const [rangeactive, setrangeactive] = useState(false);
  //PS_SO-GI-20
  const [agreementType, setagreementType] = useState('Rental');
  //PS_SO-GI-21
  const [paymentScheduleEnabled, setpaymentScheduleEnabled] = useState(true);
  //PS_SO-GI-22
  useEffect(() => {
    if (generalInfoValue.scheduleOption[0].value.includes('SEMI') === true) {
      const Rangeval = generalInfoValue.scheduleOption.map((ele: any) => {
        const value = ele.value;
        const tempval = value.replace(/SEMI/g, '');
        const tempArr = tempval.split('-');
        const finalrange = Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
        return { value: ele.value, label: finalrange };
      });
      setdropdownOptions({
        ...dropdownOptions,
        agreementSource: generalInfoValue.agreementSourceOption,
        schedule: generalInfoValue.scheduleOption,
        deliveryCharge: generalInfoValue.deliveryChargeOption,
        daysExtension: generalInfoValue.daysExtensionOption,
        promoCode: generalInfoValue.promoCodeOption,
        optServices: generalInfoValue.optServicesOption,
        range: Rangeval,
      });
    } else {
      setdropdownOptions({
        ...dropdownOptions,
        agreementSource: generalInfoValue.agreementSourceOption,
        schedule: generalInfoValue.scheduleOption,
        deliveryCharge: generalInfoValue.deliveryChargeOption,
        daysExtension: generalInfoValue.daysExtensionOption,
        promoCode: generalInfoValue.promoCodeOption,
        optServices: generalInfoValue.optServicesOption,
      });
    }

    if (
      generalInfoValue.paySchedule.schedule !== null &&
      generalInfoValue.paySchedule.daysPaid == null
    ) {
      setpaymentScheduleEnabled(false);
    }
    setpayschedule({
      ...payschedule,
      payschedulePeriod: generalInfoValue.paySchedule.schedule,
      payscheduleDay: generalInfoValue.paySchedule.daysPaid,
    });
    settermValue(generalInfoValue.termValue);
    if (generalInfoValue.scheduleValue.includes('SEMI')) {
      setrangeactive(true);
    }
    setdueDateValue(generalInfoValue.dueDate);
    const getSalesPersonValues = async () => {
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const salesPersonRes = await getSwitchOutAgreementOptions(
        customerId,
        storeNumber
      );
      const tempsalesDropdownOptions: any = salesDropdownOptions;
      if (salesPersonRes.status === 200) {
        const salesPersonVal = salesPersonRes.data;
        console.log('salesPersonValues', salesPersonVal);
        const SPArray: any = salesPersonVal.salespersonsData.map((ele: any) => {
          return {
            value: ele.id.toString(),
            label: ele.appUserName,
          };
        });
        const DefaultsalesDropdownValue = {
          value: '',
          label: 'Select',
        };
        SPArray.splice(0, 0, DefaultsalesDropdownValue);
        console.log('SPArray GE', SPArray);
        setsalesDropdownOptions(SPArray);
      }
    };
    if (!pendingSOAgr) {
      getSalesPersonValues();
      setdropdownValues({
        ...dropdownValues,
        agreementSource: generalInfoValue.agreementSourceValue,
        schedule: generalInfoValue.scheduleValue,
        deliveryCharge: generalInfoValue.deliveryChargeValue,
        dayExtension: generalInfoValue.daysExtensionValue,
        optionalServices: generalInfoValue.optServicesValue,
        promoCode: generalInfoValue.promoCodeValue,
      });
    } else {
      setsalesDropdownOptions(generalInfoValue.salesPersonOption);
      setdropdownValues({
        ...dropdownValues,
        agreementSource: generalInfoValue.agreementSourceValue,
        schedule: generalInfoValue.scheduleValue,
        deliveryCharge: generalInfoValue.deliveryChargeValue,
        dayExtension: generalInfoValue.daysExtensionValue,
        optionalServices: generalInfoValue.optServicesValue,
        promoCode: generalInfoValue.promoCodeValue,
        salesPerson: generalInfoValue.salesPerson,
      });
    }
  }, [generalInfoValue]);
  const Addsuffix = (value) => {
    const covertvalue = parseInt(value);
    if (covertvalue < 1000) {
      const FirstCheck = covertvalue % 10;
      const SecondCheck = covertvalue % 100;
      if (FirstCheck == 1 && SecondCheck != 11) {
        return covertvalue + 'st';
      }
      if (FirstCheck == 2 && SecondCheck != 12) {
        return covertvalue + 'nd';
      }
      if (FirstCheck == 3 && SecondCheck != 13) {
        return covertvalue + 'rd';
      }
      return covertvalue + 'th';
    } else {
      return value;
    }
  };
  //PS_SO-GI-24
  const salesPersonOnchange = (value: any) => {
    setdropdownValues({ ...dropdownValues, salesPerson: value });
    generalInfoChanged(value);
    //PS_SO-GI-25
    if (value !== '') {
      setnextBtnDisabled(false);
    } else {
      setnextBtnDisabled(true);
    }
  };
  return (
    <Grid>
      <Grid container className={classes.mt1}>
        <Typography variant="h6" className={`${classes.title} ${classes.mb1}`}>
          General Information
        </Typography>
      </Grid>

      <Grid container>
        <Grid item md={12} className={classes.mb4}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container className={classes.mb2}>
                <Grid item className={agrGeneralInfoClassName.listitemstyles}>
                  <Grid
                    item
                    xs="auto"
                    className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                  >
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColor
                      }
                    >
                      Agreement Type :{' '}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                      }
                    >
                      {' ' + agreementType}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs="auto"
                    className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                  >
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColor
                      }
                    >
                      {'  '}Pay Schedule :{' '}
                    </Typography>

                    {paymentScheduleEnabled === true ? (
                      <Typography
                        variant="caption"
                        className={
                          agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                        }
                      >
                        {'  '}
                        {payschedule.payschedulePeriod !== 'Daily' &&
                        payschedule.payschedulePeriod !== 'daily'
                          ? payschedule.payschedulePeriod +
                            ' on ' +
                            payschedule.payscheduleDay
                          : payschedule.payschedulePeriod}
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        className={
                          agrGeneralInfoClassName.paymentGeneralInfoColor
                        }
                      >
                        {'  '}-
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.agreementSource}
                    defaultValue={dropdownValues.agreementSource}
                    isDisabled={true}
                    inputLabel="Agreement Source"
                  />
                </Grid>

                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.schedule}
                    name="Schedule"
                    defaultValue={dropdownValues.schedule}
                    inputLabel="Schedule"
                    isDisabled={true}
                  />
                  <Typography variant="body1" className={classes.font14}>
                    Term
                    <Typography
                      variant="caption"
                      className={`${classes.bold} ${classes.racTextGreen} ${classes.ms1}`}
                    >
                      {termValue}
                    </Typography>
                  </Typography>
                </Grid>

                {rangeactive === true ? (
                  <Grid item sm={4} md={2}>
                    <RACSelect
                      options={dropdownOptions.range}
                      name="Range"
                      defaultValue={dropdownValues.range}
                      inputLabel="Range"
                      isDisabled={true}
                    />
                  </Grid>
                ) : null}

                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.deliveryCharge}
                    defaultValue={dropdownValues.deliveryCharge}
                    inputLabel="Delivery Charge ($)"
                    isDisabled={true}
                  />
                </Grid>

                <Grid item sm={4} md={2}>
                  <RACDatePicker
                    value={dueDateValue}
                    label="Due Date"
                    name="duedate"
                    disabled={true}
                    inputProps={{
                      min: currentDate,
                      max: maxDate,
                    }}
                    classes={classes.datePickerMargin}
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.daysExtension}
                    defaultValue={dropdownValues.dayExtension}
                    isDisabled={true}
                    inputLabel="Days Extension"
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.promoCode}
                    name=" PromoCode"
                    defaultValue={dropdownValues.promoCode}
                    isDisabled={true}
                    inputLabel="Promo Code"
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={dropdownOptions.optServices}
                    name="Opt Services"
                    defaultValue={dropdownValues.optionalServices}
                    isDisabled={true}
                    inputLabel="Opt Services"
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    options={salesDropdownOptions}
                    name=" Sales Person"
                    loading={salesDropdownOptions.length === 0 ? true : false}
                    defaultValue={dropdownValues.salesPerson}
                    isDisabled={editDisableMode}
                    inputLabel="SalesPerson"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      salesPersonOnchange(e.target.value);
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
