/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-btn.svg';
import { ReactComponent as Successicon } from '../../assets/images/success-icon.svg';
import { ContainerContext } from '../../app/App'
import {
  Grid,
  Typography,
  RACModalCard,
  RACSelect,
  RACButton,
  CircularProgress,
  Card,
  CardContent,
  RACRadio,
  RACTable,
  RACTableCell,
  RACTextbox,
  RACTableRow,
  List,
  ListItem
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles'
import { fixedGridStyles } from '../../JSstyles/fixedGridStyles';
import {
  getAgreementsByCustomerId, getStore, GetstateDropdown, manageAgreementTransfer, GetEmployeeID,
  GetRole,
  UpdateActiveClub,
  CoworkerRequired,
  transferActiveClub
} from '../../api/user'
import SecondFactor from '../Rental/TwoFactorPopup/TwoFactorPopup';
import { FeatureFlagContext } from '../../context/FeatureFlagContext';



interface Params {
  customerId: string;
}


export default function AgreementTransferRequest() {
  
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  
  const gridClass = fixedGridStyles();
  const modalCardRadius = '25px !important';
  const { customerId } = useParams<Params>();
  const containerData = useContext(ContainerContext);
  const classes = agreementGlobalStyles();
  const history = useHistory();
  const [customerInformation, setCustomerInformation] = useState({
    customerName: "",
    customerLastName: "",
    coCustomerFirstName: "",
    coCustomerLastName: "",
    customerId: "",
    coCustomerId: "",
    StoreNumber: '',
    city: '',
    state: '',
    customerClub: '',    
    toStoreNumber: '',
    clubActiveState: '',
    clubStoreNumber: '',
    transferToStoreState: '',    
    agmNumbers: []
  });
  // state variable for tranfer receive input values.

  const [transferRequestValues, settransferRequestValues] = useState({
    storeNumbers: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    stateId: '',
  });
  // transfer Request states.
  const [State, setState] = useState([
    { label: '', value: '' },
  ]);
  const [payLoad, setpayLoad]: any = useState({
    storeNumbers: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    stateId: '',
  })

const clubTransferData = useRef({
  customerId: "",
  coCustomerId: "" ,
  selectedClubStore: "",
  primaryAgmsForCurrentStore: [],
  primaryClubStoreNumber: "",
  primaryClubActiveState: "",
  primaryFirstName: "",
  primaryLastName: ""
});

const coCustClubInfo = useRef({
  coCustomerClubActiveState: '',
  coCustomerClubStoreNumber: '',
  coCustomerProgramGroup: '',
  coCustomerIsClubActive: false });

const storePrograms = useRef(new Map<string, string>());

  const [hiddenLoader, sethiddenLoader] = useState(false);
  const [transferRequestTableLoader, settransferRequestTableLoader] = useState(false)
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [manageAgrErrMessage, setmanageAgrErrMessage] = useState('');
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [agreementResponse, SetagreementResponse] = useState<any>([])
  const [noRecords, setNoRecords] = useState(false);
  const [searchResultNoRecords, setsearchResultNoRecords] = useState(false)
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [moduleName, setmoduleName] = useState('');
  const [stateLoading, setstateLoading] = useState(true)
  const [bothCustomerPresentPopup, setbothCustomerPresentPopup] = useState(false);
  const [coCustomerTransferPopup, setcoCustomerTransferPopup] = useState(false);
  const [coCustomerTransferNoPopup, setcoCustomerTransferNoPopup] = useState(false);
  const [transferRequestSuccessPopup, settransferRequestSuccessPopup] = useState(false);
  const [benefitPlusPopup, setbenefitPlusPopup] = useState(false);
  const [transferTostorePopup, settransferTostorePopup] = useState(false);
  const [storeResponse, setstoreResponse] = useState<any>([]);
  const [searchresultRadio, setsearchresultRadio]: any = useState([]);
  const [searchCriteriaHidden, setsearchCriteriaHidden] = useState(false);
  const [searchResultHidden, setsearchResultHidden] = useState(false);
  const [searchResultLoader, setsearchResultLoader] = useState(false);
  const [coAgreementsPopup,setcoAgreementsPopup] = useState(false);
  const [clubTransferEnable, setClubTransferEnable] = useState(false);
  const defaultStore =  {
    value: 'Select',
    label: 'Select',
  };
  interface ComboBoxOption {
    value: string;
    label: string;
  }  
  const [clubStoreOptions, setClubStoreOptions] = useState<ComboBoxOption[]>([defaultStore]);
  const [selectedClubStore, setSelectedClubStore] = useState<string>('Select');
  const [enteredAsCoRenterClubSameStore, setEnteredAsCoRenterClubSameStore] = useState(false);
  
  
  const [allAgmForCustomer, setAllAgmForCustomer]: any = useState<any[]>([]);
  let oldestStore = '';
  const [transferManyStoresClub, setTransferManyStoresClub] = useState(false);
  const [autoTransferOneClub, setAutoTransferOneClub] = useState(false);
  const [autoTransferManyClub, setAutoTransferManyClub] = useState(false);
  const [autoCancelOneStoreClub, setAutoCancelOneStoreClub] = useState(false);
  
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodestore = Buffer.from(storeDetails, 'base64');
  const decodestore: any = JSON.parse(encodestore.toString('ascii'));

  
  const currentState = decodestore.state

  useEffect(() => {
    getAgreementsByCustomerIdFn();
    setmoduleName('AgreementTransfer')
    getStateFn()
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      if (GetcurrentUser.status == 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);

        if (GetMenuDetails.status == 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;

          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData) {
      setcurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);


  useEffect(() => {

    if (TwoFactorCompleted && !TwoFactorCancelClick) {
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
      setsecondFactorEnabled(false);
      twoFactorCompletedFn();
    }

    if (TwoFactorCancelClick && !TwoFactorCompleted) {
      setsecondFactorEnabled(false);
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);


  const getAgreementsByCustomerIdFn = async () => {
    setNoRecords(true);
    settransferRequestTableLoader(true)
    const AgrTrRecRes: any = await getAgreementsByCustomerId(customerId);
    settransferRequestTableLoader(false);
    if (AgrTrRecRes.status == 200) {
      const CustomerCoCusName = AgrTrRecRes?.data
      let clubActiveState = AgrTrRecRes?.data?.clubInfoResponse;
      
      let AgrTrRecData = AgrTrRecRes.data.agreement;
      
      AgrTrRecData = AgrTrRecData.filter((agreement) => agreement.storeNumber == window.sessionStorage.getItem('storeNumber'))
      
      let clubActive;
      
      let clubStoreNumber;
      if ( clubActiveState && clubActiveState.length > 0 ) {
        clubActive = (clubActiveState[0].agreementStatusRef == 'A' ) ? '1' : '0';
      clubStoreNumber = clubActiveState[0].storeNumber;
      }

      clubActiveState = clubActiveState?.map((obj: any) => {
        return (obj.stateProvinceAbbreviation)
      })
      
      
      const agmStoreNumbers = AgrTrRecRes.data.agreement.map((obj: any) => {        
        return (obj.storeNumber)
      })
      setAllAgmForCustomer( AgrTrRecRes.data.agreement );

      setCustomerInformation({ ...customerInformation, agmNumbers: agmStoreNumbers,  customerName: CustomerCoCusName.customerFirstName, customerLastName: CustomerCoCusName.customerLastName,coCustomerFirstName:CustomerCoCusName.coCustomerFirstName,coCustomerLastName:CustomerCoCusName.coCustomerLastName, customerId: CustomerCoCusName?.customerId, coCustomerId: CustomerCoCusName?.coCustomerId, StoreNumber: AgrTrRecData[0]?.storeNumber, city: AgrTrRecData[0]?.storeCity, state: AgrTrRecData[0]?.stateProvinceAbbreviation, customerClub: clubActive, clubActiveState: clubActiveState, clubStoreNumber: clubStoreNumber })
      AgrTrRecData?.length > 0 ? (SetagreementResponse(AgrTrRecData), setNoRecords(false), setsearchCriteriaHidden(true)) : setNoRecords(true)
      clubTransferData.current.customerId = CustomerCoCusName?.customerId;
      clubTransferData.current.coCustomerId = CustomerCoCusName?.coCustomerId; 
      AgrTrRecData?.[0]?.customers?.find((obj: any) => {
        if (obj.customerId == customerId && obj.priority == "1") {
          clubTransferData.current.primaryClubStoreNumber = clubStoreNumber;
          clubTransferData.current.primaryClubActiveState = clubActive;
          return true;
        } else if (obj.customerId == customerId && obj.priority != "1") {
          coCustClubInfo.current.coCustomerClubActiveState = clubActive;
          coCustClubInfo.current.coCustomerClubStoreNumber = clubStoreNumber;
          return true;
        }
      });    
      
    }
    else if (AgrTrRecRes.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(AgrTrRecRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  }
  const getStateFn = async () => {
    const stateRes: any = await GetstateDropdown();
    if (stateRes.status == 200) {
      setstateLoading(false);
      const stateResponse = stateRes?.data;
      const states = stateResponse?.map((obj) => {
        return { label: obj.stateAbbreviation, value: obj.stateProvinceId }
      })
      states.splice(0, 0, {
        value: '',
        label: 'Select',
      });
      setState(states)
    }
    else {
      setmanageAgrErrMessage(stateRes?.data?.errors[0]?.error);
    }

  }


  // function for getStore
  const getStoreFn = async () => {
    setsearchResultNoRecords(false)
    setsearchResultHidden(true)
    setsearchResultLoader(true)
    const getStoreReq: any = payLoad;
    getStoreReq.storeNumbers = transferRequestValues.storeNumbers ? transferRequestValues.storeNumbers :
      '';
    getStoreReq.phoneNumber = transferRequestValues.phoneNumber;
    getStoreReq.addressLine1 = transferRequestValues.addressLine1.trimEnd();
    getStoreReq.addressLine2 = transferRequestValues.addressLine2.trimEnd();
    getStoreReq.zip = transferRequestValues.zip;
    getStoreReq.city = transferRequestValues.city;
    getStoreReq.stateId =transferRequestValues.stateId? Number(transferRequestValues.stateId):'';
    setpayLoad({ ...getStoreReq })
    const getStoreRes: any = await getStore(getStoreReq);
    setsearchResultLoader(false)
    if (getStoreRes?.status == 200) {
      const getStoreData = getStoreRes?.data?.response
      getStoreData?.length > 0 ? (setstoreResponse(getStoreData),
        setCustomerInformation({ ...customerInformation, toStoreNumber: getStoreData[0].storeNumber, transferToStoreState: getStoreData[0].stateAbb })
      ) : setsearchResultNoRecords(true)
      console.log("toStoreDefault", getStoreData[0].storeNumber)
      const radioArray: any = new Array(getStoreData?.length);
      radioArray.fill(false);
      radioArray[0] = true;
      console.log("readioArray", radioArray)
      setsearchresultRadio(radioArray)
    }
    else if (getStoreRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(getStoreRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  }

  // transfer request function
  const transferRequestFn = async () => {
    sethiddenLoader(true)
    const updateTransferReq = {
      action: 'TIN',
      customerIds: [customerInformation?.customerId ? customerInformation?.customerId : customerInformation?.coCustomerId],
      toStoreNumber: customerInformation.toStoreNumber,
      currentStoreNumber: window.sessionStorage.getItem('storeNumber')
    }

    const transferReqRes = await manageAgreementTransfer(updateTransferReq);
    sethiddenLoader(false)
    if (transferReqRes?.status == 200) {
      settransferRequestSuccessPopup(true)

    }
    else if (transferReqRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(transferReqRes?.data?.errors[0]?.error)
    }
    else {
      setSomethingWentWrongOpen(true)
    }
  }
  const getOpenDate = ( agmNumber : string ) : string => {
    const agreement = allAgmForCustomer.find((agreement) => agreement.storeNumber == agmNumber);
    return agreement?.agreementOpenDate;  
  }

  const buildAvailableStores = ( currentStoreNumber : string ) : void => {
    let oldest = '';
    let oldStore = '';
    const currentProgramId = storePrograms.current.get(currentStoreNumber);
    const stores :ComboBoxOption[] = [defaultStore];

    for (const [key, value] of storePrograms.current.entries()) {   
      if ( key != currentStoreNumber && value == currentProgramId )  {
        stores.push( { label: key, value: key });
        const openDate = getOpenDate(key);
        if ( !oldest ) {
          oldest = openDate;
          oldStore = key;
        } 
        
        if ( openDate < oldest ) {
          oldest = openDate;
          oldStore = key;            
        }        
      }
    }   
    oldestStore = oldStore
    setClubStoreOptions(stores);
  }

  const setCoCustomerProgramGroup = () => {
    if ( coCustClubInfo.current.coCustomerClubStoreNumber ) {
      const coCustPG = storePrograms.current.get( coCustClubInfo.current.coCustomerClubStoreNumber );

      if ( coCustPG ) {
        coCustClubInfo.current.coCustomerProgramGroup = coCustPG;
      }
    }

  }
  
  const buildClubProgramMap =  (resp : any, ignoreCoCustStoreNum : any ) : void => {
    for( const oneConfig of resp?.storeProfileResponse?.configDetails ) {
      const oneStoreNum = oneConfig?.storeNumber;
      const  programId = oneConfig?.configDetails[0]?.paramValue;
      if ( oneStoreNum != ignoreCoCustStoreNum ) {
        storePrograms.current.set( oneStoreNum, programId);
      }  else if ( ignoreCoCustStoreNum ) {
        coCustClubInfo.current.coCustomerProgramGroup = programId;
      }      
    }

  };

  const  buildClubTransferContext = async (currentStoreNumber : string) :Promise<any> => {

    const {agmNumbers,toStoreNumber} = customerInformation;
    let coCustProgramGroup : any = undefined;

    const allstores : any = Array.from(new Set( [...agmNumbers, toStoreNumber, clubTransferData.current.primaryClubStoreNumber].filter(v => v)));

    
    if ( coCustClubInfo.current.coCustomerClubStoreNumber && 
         !allstores.includes( coCustClubInfo.current.coCustomerClubStoreNumber ) ) {
        allstores.push( coCustClubInfo.current.coCustomerClubStoreNumber );
        coCustProgramGroup = coCustClubInfo.current.coCustomerClubStoreNumber;
      
    } 

    const payload  = {
      storeNumbers: allstores,
      paramKeyNames: ['BenefitPlusClubId']
    };

    const out = await CoworkerRequired( payload );
    if ( out.status == 200 ) {
      buildClubProgramMap( out.data, coCustProgramGroup )      
      buildAvailableStores( currentStoreNumber );
      setCoCustomerProgramGroup();
      return out.data;
    }
  }

  const isEnteredAsCoRenterClubSameStore = (currentStoreNumber : string) : boolean => {
    if ( customerId == customerInformation?.coCustomerId &&
        coCustClubInfo.current.coCustomerClubStoreNumber === currentStoreNumber && 
        coCustClubInfo.current.coCustomerIsClubActive  ) {
        // entered as corenter they have an active club in the current store !
       
        return true;
      }      

    return false;
  }

  const isAutoTransfer = ( currentStoreNumber : string) : boolean => {
    const storeNum = customerInformation.toStoreNumber;
    const toProgramId = storePrograms.current.get(storeNum);    
    const currentProgramId = storePrograms.current.get(currentStoreNumber);
    
    if ( currentProgramId && programExistsInAnotherStore(currentProgramId, currentStoreNumber) === 1  && toProgramId === currentProgramId ) {
      setSelectedClubStore(storeNum );
      clubTransferData.current.selectedClubStore = storeNum;
      return true;
    }

    return false;
  }
  const isTransferManyStoresClubPopup = ( currentStoreNumber : string ) : boolean => {
    const storeNum = customerInformation.toStoreNumber;
    const toProgramId = storePrograms.current.get(storeNum);    
    const currentProgramId = storePrograms.current.get(currentStoreNumber);

    if ( storePrograms.current.size > 2   && currentProgramId && toProgramId && toProgramId === currentProgramId && 
      programExistsInAnotherStore(currentProgramId, currentStoreNumber) > 1 && 
      clubTransferData.current.primaryClubStoreNumber === currentStoreNumber ) {
        setSelectedClubStore(storeNum);
        clubTransferData.current.selectedClubStore = storeNum;
        return true;
    }

    return false;
  }

  const isAutoTransferManyClubPopup = ( currentStoreNumber : string ) : boolean => {
    const storeNum = customerInformation.toStoreNumber;
    const toProgramId = storePrograms.current.get(storeNum);    
    const currentProgramId = storePrograms.current.get(currentStoreNumber);

    if ( storePrograms.current.size > 2   && currentProgramId && toProgramId && toProgramId !== currentProgramId && 
      programExistsInAnotherStore(currentProgramId, currentStoreNumber) > 1 && 
      clubTransferData.current.primaryClubStoreNumber === currentStoreNumber ) {
        // pick oldest 
        setSelectedClubStore(oldestStore);
        clubTransferData.current.selectedClubStore = oldestStore;
        return true;
      }

    return false;
  }

  const isAutoTransferOneClubPopup = ( currentStoreNumber : string ) : boolean => {
    const storeNum = customerInformation.toStoreNumber;
    const toProgramId = storePrograms.current.get(storeNum);    
    const currentProgramId = storePrograms.current.get(currentStoreNumber);

    if ( storePrograms.current.size > 2   && currentProgramId && toProgramId && toProgramId !== currentProgramId && 
      programExistsInAnotherStore(currentProgramId, currentStoreNumber) === 1 && 
      clubTransferData.current.primaryClubStoreNumber === currentStoreNumber) {
      
        setSelectedClubStore(oldestStore);
        clubTransferData.current.selectedClubStore = oldestStore;
        return true;
      }

    return false;
  }
  const isAutoCancelOneStoreClubPopup = ( currentStoreNumber : string ) : boolean => {
    const storeNum = customerInformation.toStoreNumber;
    const toProgramId = storePrograms.current.get(storeNum);    
    const currentProgramId = storePrograms.current.get(currentStoreNumber);

    if ( storePrograms.current.size > 1  && currentProgramId && toProgramId && toProgramId !== currentProgramId && 
      programExistsInAnotherStore(currentProgramId, currentStoreNumber) < 1  ) {

        // must check if we are in the entered as a coborrower case with a primary borrower with multiple agreements 
        if (enteredAsCoBorrowerPrimaryHasMoreAgms() ) {
          return false;
        }
        return true;
      }
    return false;
  }
  const programExistsInAnotherStore = ( programToLookFor : string, currentStoreNumber : string ) : number => {
    let count = 0;
    for (const [key, value] of storePrograms.current.entries()) {   
      key != currentStoreNumber && value == programToLookFor && (count = count + 1);      
    }
    return count;
  }
  
  const transferCoCustomerClub = async( ) => { 
    
    if ( await shouldTransferCoCustomerClub() ) {
      
      const payload  = {      
        "fromStore": coCustClubInfo.current.coCustomerClubStoreNumber,
        "customerId": Number(clubTransferData.current.coCustomerId),
        "transferToStore": clubTransferData.current.selectedClubStore
      };

      const out = await transferActiveClub( payload );
      
      if ( out?.status != 200 ) {      
        setSomethingWentWrongOpen(true);
        return false; 
      } 
    }

    return true; 
  }


  const autoTransferClub = async( ) => { 
    
    const currentStoreNumber =  window.sessionStorage.getItem('storeNumber');   
    const payload  = {      
      "fromStore": currentStoreNumber,
      "customerId": Number(clubTransferData.current.customerId),
      "transferToStore": clubTransferData.current.selectedClubStore
    };

    const out = await transferActiveClub( payload );
    
    if ( out?.status != 200 ) {      
      setSomethingWentWrongOpen(true);
      return false; 
    } else {
      return await transferCoCustomerClub();
    }
  }

  const autoCancelClub = async() => { 
    
    const payload  = {      
      "isCreate":0,
      "customerId": Number(clubTransferData.current.customerId),
      "clubStatusRefCode":"C"
    };

    return await UpdateActiveClub( payload );    
  }

  const autoCancelThenTransferClub = async() => { 
  
    const out = await autoCancelClub();
    if ( out.status != 200 ) {
      setAutoCancelOneStoreClub(false);
      setSomethingWentWrongOpen(true);
    } else {
      settransferTostorePopup(true)
      setAutoCancelOneStoreClub(false);
    }
  }
  const clubsInFutureState = (primaryClubState, coCustomerClubState) => {
    let allInFutureState = 1;
    primaryClubState && (primaryClubState == customerInformation?.transferToStoreState ? allInFutureState & 1 : allInFutureState = 0);
    coCustomerClubState && (coCustomerClubState == customerInformation?.transferToStoreState ? allInFutureState & 1 : allInFutureState = 0);
    return (allInFutureState==1);
  }

  const stopTransfer = () => {  setEnteredAsCoRenterClubSameStore(true);  }
  const singleCancelOneClub = () => {  setAutoCancelOneStoreClub(true);  }
  const singleTransferManyStores = () => {  setTransferManyStoresClub(true);  }
  const singleAutoTransferManyClub = () => {  setAutoTransferManyClub(true);  }
  const singleAutoTransferOneClub = () => {  setAutoTransferOneClub(true);  }
  const defaultProcess = () => {  settransferTostorePopup(true);  }
  const singleAutoTransfer = async () => { await autoTransferClub () && settransferTostorePopup(true)  }
  const isTrue = () => { return true; }
  
  const singleClubTransferFx = [
    { evalFx: isEnteredAsCoRenterClubSameStore, exerciseFx: stopTransfer },
    { evalFx: isAutoTransfer, exerciseFx: singleAutoTransfer },
    { evalFx: isAutoTransferOneClubPopup, exerciseFx: singleAutoTransferOneClub },
    { evalFx: isAutoTransferManyClubPopup, exerciseFx: singleAutoTransferManyClub },
    { evalFx: isTransferManyStoresClubPopup, exerciseFx: singleTransferManyStores },
    { evalFx: isAutoCancelOneStoreClubPopup, exerciseFx: singleCancelOneClub },
    { evalFx: isTrue, exerciseFx: defaultProcess }
    
  ];
 

  const processSingleClubTransfer = async () => {
    clubTransferData.current.customerId = customerInformation?.customerId;
    clubTransferData.current.primaryClubStoreNumber = customerInformation?.clubStoreNumber;
    clubTransferData.current.primaryClubActiveState = customerInformation?.clubActiveState;
    await processClubTransfer(singleClubTransferFx);    
  }

  const shouldTransferCoCustomerClub = async () => {
    if ( coCustClubInfo.current.coCustomerIsClubActive && coCustClubInfo.current.coCustomerClubStoreNumber && clubTransferData.current.primaryClubStoreNumber  ) {
      const coCustomerProgramId = coCustClubInfo.current.coCustomerProgramGroup;
      const primaryProgramId  = storePrograms.current.get( clubTransferData.current.primaryClubStoreNumber );
      if ( primaryProgramId && coCustomerProgramId && coCustomerProgramId ===  primaryProgramId && 
        clubTransferData.current.selectedClubStore != coCustClubInfo.current.coCustomerClubStoreNumber) {
        // make sure the club is not already in the to store         
        return true;        
      }

    } 

    return false;
  }
  const processMultiBorrowerClubTransfer = async (primaryIsClubActive, primaryClubState, coCustomerClubState, coCustomerIsClubActive, 
    primaryClubStore, coCustomerClubStore ) => {
      
    coCustClubInfo.current.coCustomerClubActiveState = coCustomerClubState;
    coCustClubInfo.current.coCustomerClubStoreNumber = coCustomerClubStore;
    coCustClubInfo.current.coCustomerIsClubActive = coCustomerIsClubActive== '1' ? true : false;;
    
    clubTransferData.current.primaryClubStoreNumber = primaryClubStore;
    clubTransferData.current.primaryClubActiveState = primaryClubState;

    const currentStoreNumber =  window.sessionStorage.getItem('storeNumber');    

    if ( (customerInformation?.customerClub?.[0] === '1' && customerInformation?.clubStoreNumber === currentStoreNumber) ||
         (primaryIsClubActive === '1' && primaryClubStore === currentStoreNumber) ) {
      // we either have 1 or 2 active clubs but one of them MUST be the primary's club
      
      if ( featureFlagDetails?.EnableClubTransfer === '1' && currentStoreNumber ) {    

        await processClubTransfer(singleClubTransferFx);  

      } else {      
        // it is turned OFF so default to current behavior
        // if clubs are in the same state process the transfer show current pop up 
        if ( clubsInFutureState(primaryClubState, coCustomerClubState) ) {
          settransferTostorePopup(true);
        } else {
          setbenefitPlusPopup(true);
        }
      }

    } else {
      // no primary clubs are active so keep processing 
      settransferTostorePopup(true);
    }    
  }

  const processClubTransfer = async ( clubTransferFx ) => {

    const currentStoreNumber =  window.sessionStorage.getItem('storeNumber');    
    
    if ( featureFlagDetails?.EnableClubTransfer === '1' && currentStoreNumber ) {
      // so it is turned ON truthy so now let us see if we should do the transfer of the club 
      // let's get the customer info and get the benefits plus info for each store 
            
      if ( await buildClubTransferContext(currentStoreNumber) ) {
      
        for (const { evalFx, exerciseFx } of clubTransferFx) {
          if (evalFx(currentStoreNumber)) {              
              await exerciseFx(); 
              break; 
          }        
        }
      } else {
        setSomethingWentWrongOpen(true);
      }
      
    } else {      // it is turned OFF so default to show current pop up 
      setbenefitPlusPopup(true);
    }    

  }

  const processBorrower= async (id: string) => {
    const custData: any = await getAgreementsByCustomerId(id);
    
    if (custData.status == 200) {      
      const clubInfoResponse = custData?.data?.clubInfoResponse;      
      const allAgreements = custData.data.agreement;     

      const customerClubState = clubInfoResponse?.[0]?.stateProvinceAbbreviation;
      const customerClubStore = clubInfoResponse?.[0]?.storeNumber;
      const isClubActive = (clubInfoResponse?.[0]?.agreementStatusRef == 'A' ) ? '1' : '0';

      return { isClubActive, customerClubState, customerClubStore, allAgreements };  
    }      
  }
  const getPrimaryCustId = ( allAgreements : any ) => {
    const customers = Array.isArray(allAgreements) ? allAgreements[0].customers : allAgreements.customers;
    
    return customers.find(oneCust => (oneCust.priority === '1'))?.customerId;
  }
  const getPrimaryFirstName = ( allAgreements : any ) => {
    const customers = Array.isArray(allAgreements) ? allAgreements[0].customers : allAgreements.customers;
    
    return customers.find(oneCust => (oneCust.priority === '1'))?.firstName;
  }
  const getPrimaryLastName = ( allAgreements : any ) => {
    const customers = Array.isArray(allAgreements) ? allAgreements[0].customers : allAgreements.customers;
    
    return customers.find(oneCust => (oneCust.priority === '1'))?.lastName;
  }

  const multiCustWhenEnteredAsCoCustomer = async () => {
    const coCustomerClubState = customerInformation?.clubActiveState?.[0];
    const coCustomerIsClubActive = customerInformation?.customerClub?.[0];
    const coCustomerClubStore = customerInformation?.clubStoreNumber;
    let primaryCustomerID : any = customerInformation?.customerId;

    // now get the primary customer club information 
    if ( !primaryCustomerID ) { // since we entered the screen as cocustomer no primaryID was kept
      primaryCustomerID = getPrimaryCustId( allAgmForCustomer);
    }
    if ( primaryCustomerID ) {      
      clubTransferData.current.customerId = primaryCustomerID;
      clubTransferData.current.coCustomerId = customerInformation?.coCustomerId;
      
      const out = await processBorrower(primaryCustomerID);
      if ( out ) {
        const {isClubActive, customerClubState, customerClubStore, allAgreements} = out
      
        clubTransferData.current.primaryAgmsForCurrentStore = allAgreements;
        clubTransferData.current.primaryFirstName = getPrimaryFirstName(allAgmForCustomer);
        clubTransferData.current.primaryLastName = getPrimaryLastName(allAgmForCustomer);
        const primaryClubState = customerClubState;
        const primaryIsClubActive = isClubActive;
        const primaryClubStore = customerClubStore;
      
        await processMultiBorrowerClubTransfer(primaryIsClubActive, primaryClubState, coCustomerClubState, coCustomerIsClubActive,
          primaryClubStore, coCustomerClubStore );
        
      } else {
        // bad things happen do not continue 
        setSomethingWentWrongOpen(true);
      }
    } else {
      // bad things happen do not continue 
      setSomethingWentWrongOpen(true);
    }
  }

  const multiCustWhenEnteredAsPrimary= async () => {
    const primaryClubState = customerInformation?.clubActiveState?.[0];
    const primaryIsClubActive  =  customerInformation?.customerClub?.[0];
    const primaryClubStore = customerInformation?.clubStoreNumber;
    
    // now get the cocustomer club information      
    const out = await processBorrower(customerInformation?.coCustomerId);
    if ( out ) {
      const {isClubActive, customerClubState, customerClubStore } = out;

      clubTransferData.current.customerId = customerInformation?.customerId;
      clubTransferData.current.coCustomerId = customerInformation?.coCustomerId;
      clubTransferData.current.primaryAgmsForCurrentStore = customerInformation?.agmNumbers;

      coCustClubInfo.current.coCustomerClubActiveState = customerClubState;
      coCustClubInfo.current.coCustomerClubStoreNumber = customerClubStore;
      coCustClubInfo.current.coCustomerIsClubActive = isClubActive == '1' ? true : false;
     
      await processMultiBorrowerClubTransfer(primaryIsClubActive, primaryClubState, customerClubState, isClubActive,
        primaryClubStore, customerClubStore );
      
    } else {
      // bad things happen do not continue 
      setSomethingWentWrongOpen(true);
    }
  }

  const enteredAsCoBorrowerPrimaryHasMoreAgms  = () => {
  
    if ( customerId == customerInformation?.coCustomerId ) {
      // enterred as coCustomer
      const agms = clubTransferData.current.primaryAgmsForCurrentStore as any;
      const currentStoreAgms = agms?.filter(
        (agreement) => agreement.storeNumber == window.sessionStorage.getItem('storeNumber'))
      
      // does the primary have more agms?
      const agmStoreNumbers = currentStoreAgms?.map((obj: any) => {        
        return (obj.storeNumber)
      });

      if ( agmStoreNumbers && 
           agmStoreNumbers?.length > customerInformation?.agmNumbers?.length ) {
          // the primary has more agms 
          return true;
      }
    } 
    return false;
  }

 
  const multiCustomerClubCheck = async () => {
    if ( featureFlagDetails?.EnableClubTransfer === '1' ) {
      // if you have a cocustomer then you MUST have a primary.  Determine if there are 0, 1 or 2 clubs at play
      // but are you entering as the primary or the cocustomer ? 
      if ( customerId == customerInformation?.coCustomerId ) {
        // you enterred the screen as the coCustomer      
        await multiCustWhenEnteredAsCoCustomer();      
      } else {
        await multiCustWhenEnteredAsPrimary();
      }
    } else {
      await customerClubCheckFn();
    }
  }

  const customerClubCheckFn = async () => {
    if (currentState == customerInformation?.clubActiveState[0] && customerInformation?.clubActiveState[0] != undefined) {
      if (currentState == customerInformation?.transferToStoreState) {
        if (customerInformation?.customerClub[0] == '1') {
          settransferTostorePopup(true)
        }
        else {
          // current functionality is to prevent transfers if a club is present !          
          setbenefitPlusPopup(true);          
        }
      }
      else {
        // current functionality is to prevent transfers if a club is present !         
        setbenefitPlusPopup(true);        
      }
    }
    else {
      settransferTostorePopup(true);
    }
  }


  //two factor completed function
  const twoFactorCompletedFn = async () => {
    const customerOrderAgrRefCode=['PRELIM','PRELIMINV','PRELIMPAID','PRELIMRFND'];
    let isCOAgreements = false;
    for(let index = 0; index < agreementResponse?.length; index++) {
      if(customerOrderAgrRefCode.includes(agreementResponse[index].agreementRefCode)) {
        setcoAgreementsPopup(true);
          isCOAgreements = true
          break;
      }
    }

    if(!isCOAgreements) {
      
      if(customerInformation?.customerId!=null && customerId==customerInformation?.customerId){
        if(customerInformation?.coCustomerId!= null){
          setbothCustomerPresentPopup(true)
        }
        else if (currentState== customerInformation?.clubActiveState[0] && customerInformation?.clubActiveState[0] != undefined) {
          // in the SAME state 
          if (currentState == customerInformation?.transferToStoreState) {
            if (customerInformation?.customerClub[0] == '1' && featureFlagDetails?.EnableClubTransfer != '1') {
              settransferTostorePopup(true)
            }
            else {
              // current functionality is to prevent transfers if a club is present !
              await processSingleClubTransfer();
            }
          }
          else{
            // current functionality is to prevent transfers if a club is present !
            await processSingleClubTransfer();
          }
        }
        else{ 
          // different state
          settransferTostorePopup(true)
        }
      }
      else if(customerInformation?.coCustomerId!= null && customerId == customerInformation?.coCustomerId){
        setbothCustomerPresentPopup(true)
      }
      
    }
    

  }
  // agreement transfer/receive table heading values
  const HeadArr = [
    { displayName: 'Agreement#' },
    { displayName: 'Description' },
    { displayName: 'Type' },
    { displayName: '# Payments' },
    { displayName: 'Total cost' },
    { displayName: 'Schedule' },
    { displayName: 'Next Due Date' },
    { displayName: 'Amount Due' },
    { displayName: 'Status' },
    { displayName: 'Sub Status' },
  ];
  // agreement transfer/receive search result table heading values
  const searchResultHeadArr = [
    { displayName: '' },
    { displayName: 'Store#' },
    { displayName: 'Store Description' },
    { displayName: 'Address' },
    { displayName: 'City' },
    { displayName: 'State' },
    { displayName: 'Zip Code' },
    { displayName: 'Phone#' },
  ];

  // function binding table headings
  const renderTableHeadFn = () => (
    <>
      {HeadArr.map((obj: any) => {
        return <RACTableCell>{obj.displayName}</RACTableCell>;
      })}
    </>
  );
  // function binding table headings
  const renderSearchResultTableHeadFn = () => (
    <>
      {searchResultHeadArr.map((obj: any) => {
        return <RACTableCell>{obj.displayName}</RACTableCell>;
      })}
    </>
  );
  const dateArray = ['PIF', 'SAC', 'EPO'];
  // next due date converting function
  function nextDueDateFn(date: any) {
    if (!date || dateArray.includes(date)) {
      return '-';
    } else {
      return `${new Date(date).toString().split(' ')[0]}, ${
        new Date(date).getMonth() + 1
      }/${new Date(date).getDate()}/${new Date(date).getFullYear()}`;
    }
  }

  // text hide funxtion
  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };

  const renderTableContentFn = () => (
    <>
      {
        noRecords ?
          <>
            <RACTableRow>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell style={{ "float": "right" }}>
                {transferRequestTableLoader ?
                  <>
                    <Grid style={{ "marginTop": "50px" }}>
                      <CircularProgress />
                    </Grid>
                  </>
                  :
                  <>
                    <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                      <NoRecordsFound />
                      <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                        No records found
                      </Typography>
                    </Grid>
                  </>
                }
              </RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
              <RACTableCell></RACTableCell>
            </RACTableRow>
          </>
          : <>
            <RACTableRow>
              <RACTableCell style={{ fontFamily: 'OpenSans-bold' }}>{customerInformation.StoreNumber + '-' + customerInformation.city + ',' + customerInformation.state}</RACTableCell>
            </RACTableRow>
            {agreementResponse.map((obj: any) => {
              return (
                <RACTableRow>
                  <RACTableCell
                    className={`${classes.fontstyle} ${classes.activeAgreementTransfer}`}
                    onClick={() => {
                      history.push({
                        pathname: `/agreement/info/details/${customerId}/${obj.agreementId}`,
                      });
                      const navEvent = new PopStateEvent('popstate');
                      window.dispatchEvent(navEvent);
                    }}
                    style={{ cursor: 'pointer', fontFamily: 'OpenSans-bold', "marginTop": "1px", "left": "9px" }}
                  >
                    {obj.agreementNumber}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }} title={obj.agreementDescription}>
                    {hideLengthyText(obj.agreementDescription, 20, '...')}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>{obj.agreementType}</RACTableCell>
                  <RACTableCell style={{ color: 'gray', textAlign: 'center' }}>
                    {obj.numberOfPayments}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray', textAlign: 'center' }}>
                    ${obj.totalCost}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {obj.paymentSchedule}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {nextDueDateFn(obj.nextDueDate)}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray', textAlign: 'center' }}>
                    ${obj.amountDue}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>{obj.agreementStatus}</RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {obj.agreementSubstatus}
                  </RACTableCell>
                </RACTableRow>
              );
            })}
          </>
      }
    </>
  );

  const radioClick = (index) => {
    debugger;
    console.log(searchresultRadio);
    const updatedRadio = searchresultRadio.map((item, position) => {
      console.log('index', index);
      console.log('position', position);
      console.log('item', item);
      return index == position ? !item : false

    });
    setsearchresultRadio([...updatedRadio]);
    {
      searchresultRadio.map((item, position) => {
        if (index == position) {
          setCustomerInformation({
            ...customerInformation, toStoreNumber: storeResponse[index].storeNumber,
            transferToStoreState: storeResponse[index].stateProvinceAbbreviation
          })
          console.log("toStoreNumber", storeResponse[index].storeNumber);
        }
      })
    }
  }

  // Search result table body binding function

  function renderSearchResultTableContentFn() {
    return (
      <>
        {
          searchResultNoRecords ?
            <>
              <RACTableRow>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell style={{ "textAlign": "center" }}>
                  <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                    <NoRecordsFound />
                    <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                      No records found
                    </Typography>
                  </Grid>
                </RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
              </RACTableRow>
            </> :
            <>
              {storeResponse.map((obj: any, index) => {
                return (
                  <RACTableRow>
                    <RACTableCell>
                      <RACRadio
                        value=''
                        checked={searchresultRadio[index] === false ? false : true}
                        onClick={() => {
                          radioClick(index)
                        }}
                      />

                    </RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.storeNumber}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.companyName}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.addressLine1}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.city}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.stateAbb}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.zip}</RACTableCell>
                    <RACTableCell style={{ color: 'gray' }}>{obj.workPhoneNumber}</RACTableCell>
                  </RACTableRow>
                )
              })}
            </>
        }
      </>

    )
  }


  // handle handleAgrTrRecInput function setting input values in object
  const handleChangeInput = (e: any) => {

    if (e.target.name == 'storeNumber') {
      settransferRequestValues({ ...transferRequestValues, storeNumbers: e.target.value.trim() })
    }
    if (e.target.name == 'phoneNumber') {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, '($1) $2-$3');
      settransferRequestValues({
        ...transferRequestValues, phoneNumber: temp,
      });
    }
    if (e.target.name == 'addressLine1') {
      settransferRequestValues({ ...transferRequestValues, addressLine1: e.target.value.trimStart() })
    }
    if (e.target.name == 'addressLine2') {
      settransferRequestValues({ ...transferRequestValues, addressLine2: e.target.value.trimStart() })
    }
    if (e.target.name == 'city') {
      settransferRequestValues({ ...transferRequestValues, city: e.target.value.trim() })
    }
    if (e.target.name == 'zipCode') {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      const ziptr = /^\(?([0-9]{5})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(ziptr, '$1-$2');
      settransferRequestValues({ ...transferRequestValues, zip: temp })
    }
    if (e.target.name == 'state') {
      settransferRequestValues({ ...transferRequestValues, stateId: e.target.value })
    }
    console.log("StoreSearchInput", transferRequestValues)
  };
  // handle function for clear all store search field
  const handleClear = () => {
    settransferRequestValues({
      ...transferRequestValues,
      storeNumbers: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      city: "",
      stateId: "",
    })
  };

  // Click Enter In Keyboard
  const EnterOnClick = (e: any) => {
    if (
      e.key == 'Enter' &&
      (transferRequestValues.storeNumbers.length > 0 ||
        transferRequestValues.phoneNumber.length > 0 ||
        transferRequestValues.addressLine1 !== '' ||
        transferRequestValues.addressLine2 !== '' ||
        transferRequestValues.zip.length > 0 ||
        transferRequestValues.city !== '' ||
        transferRequestValues.stateId !== '')
    ) {
      getStoreFn();
    }
  };

  

  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
              setsearchResultNoRecords(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {manageAgrErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
              setsearchResultNoRecords(true)
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const customerOrderPendingAgreementsPopupFn=()=>{
    return(
      <>
      <Typography style={{textAlign: 'center', fontFamily:'OpenSans-bold', lineHeight: '1.3rem'}}>Customer has pending Customer Order Agreement(s).Transfer cannot be completed until the agreement becomes Active or Inactive.</Typography>
      <RACButton
      variant='contained'
      color='primary'
    onClick={()=>{
      setcoAgreementsPopup(false)
    }}
    style={{"marginLeft":"40%","marginTop":"5%"}}
      >
        Ok
      </RACButton>
      </>
    )
  }
  const bothCutomerPresentPopupFn = () => {
    return (
      <>
        <Typography style={{ "fontSize": "15px", "textAlign": "center", "marginTop": "-12px" }}>There is another renter on the agreements. Are both parties Present ?</Typography>
        <RACButton
          style={{
            marginTop: '35px',
            marginLeft: '100px',
            padding: '10px 30px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setbothCustomerPresentPopup(false)
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '35px',
            padding: '10px 33px',
            marginLeft: '18px',
          }}
          onClick={() => {
            setbothCustomerPresentPopup(false),
              setcoCustomerTransferPopup(true)
          }}
        >
          Yes
        </RACButton>
      </>
    )
  }
  const otherRenterTransferPopup = () => {
    return (
      <>
        <Typography style={{ "fontSize": "16px", "textAlign": "center", "marginTop": "-17px" }}>Is the other renter transferring to the new store?</Typography>
        <RACButton
          style={{
            marginTop: '25px',
            marginLeft: '100px',
            padding: '10px 30px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setcoCustomerTransferPopup(false)
            setcoCustomerTransferNoPopup(true)
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '25px',
            padding: '10px 30px',
            marginLeft: '18px',
          }}
          onClick={async () => {
            setcoCustomerTransferPopup(false);
            await multiCustomerClubCheck();
          }}
        >
          Yes
        </RACButton>
      </>
    )
  }
  const otherCustomerTransferNopopup = () => {
    return (
      <>
        <Typography style={{ "fontSize": "16px", "textAlign": "center", "marginTop": "-17px" }}>Please remove the other renter and try again.</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '20px',
            marginLeft: '157px',
            padding: '12px 30px',
          }}
          onClick={() => {
            setcoCustomerTransferNoPopup(false);
            history.push({
              pathname: `/customer/update/${customerId}/customer`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
          }}
        >Ok</RACButton>
      </>
    )
  }
  const transferRequestSuccessPopupFn = () => {
    return (
      <>
        <div id="testimage" className={classes.agrTranferSuccessImg}>
          <Successicon></Successicon>
        </div>
        <Typography style={{ "textAlign": "center", "fontSize": "17px" }}>Agreement Transfer Request Sent successfully</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '20px',
            marginLeft: '170px',
            padding: '12px 30px',
          }}
          onClick={() => {
            settransferRequestSuccessPopup(false);
            history.push({
              pathname: `/dashboard`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
          }}
        >Ok</RACButton>
      </>
    )
  }
  const autoCancelOneStoreClubPopup = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>The agreement can not be transferred since the other store has a 
                                                                    <br />  different club program.  Would you like to cancel the club now 
                                                                    <br />  and proceed with the transfer?</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '18px',
            padding: '10px 25px',
            marginLeft: '190px',
            marginRight: '10px',
          }}
          onClick={ async () => {            
            await autoCancelThenTransferClub()
          }}
        >Ok</RACButton>
        <RACButton
          variant="outlined"
          color='primary'
          style={{
            marginTop: '18px',
            marginLeft: '10px',
            padding: '10px 25px',
          }}
          onClick={() => {
            // do not do the transfer 
            setAutoCancelOneStoreClub(false);
          }}
        >Cancel</RACButton>
      </>
    )
  }
  const autoTransferManyClubPopup = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>The primary customer has agreements in multiple stores with the same 
                                                      <br/> club program.  The store being transferred to has a different program and 
                                                      <br/> will not be covered by their current club.  Please inform the customer 
                                                      <br/> they must sign up for a second Club in-store &nbsp; 
                                                      {customerInformation?.toStoreNumber ? customerInformation?.toStoreNumber : ''} &nbsp; if they wish to 
                                                      <br/> have a Club program in that store.</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '18px',
            padding: '10px 25px',
            marginLeft: '190px',
            marginRight: '10px',
          }}
          onClick={async () => {

            // set auto transfer on to oldest agreement not in this store but in the same program
            if ( await autoTransferClub() ) {
              setAutoTransferManyClub(false);            
              settransferTostorePopup(true)
            } else {
              setAutoTransferManyClub(false);
            }
          }}
        >Ok</RACButton>
        <RACButton
          variant="outlined"
          color='primary'
          style={{
            marginTop: '18px',
            marginLeft: '10px',
            padding: '10px 25px',
          }}
          onClick={() => {
            // do not do the transfer 
            setAutoTransferManyClub(false);
          }}
        >Cancel</RACButton>
      </>
    )
  }
  const autoTransferOneClubPopup = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>The store being transferred to has a different program and will not 
                                                                    <br/> be covered by their current club.  Please inform the customer they 
                                                                    <br/> must sign up for a second Club in-store &nbsp;
                                                      {customerInformation?.toStoreNumber ? customerInformation?.toStoreNumber : ''} 
                                                      &nbsp; if they wish to<br/>  have a Club program in that store.</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '18px',
            padding: '10px 25px',
            marginLeft: '190px',
            marginRight: '10px',
          }}
          onClick={async () => {
            // set auto transfer on to oldest agreement not in this store but in the same program
            if ( await autoTransferClub() ) {
              setAutoTransferOneClub(false);
              settransferTostorePopup(true)
            } else {
              setAutoTransferOneClub(false);
            }
            
          }}
        >Ok</RACButton>
        <RACButton
          variant="outlined"
          color='primary'
          style={{
            marginTop: '18px',
            marginLeft: '10px',
            padding: '10px 25px',
          }}
          onClick={() => {
            // do not do the transfer 
            setAutoTransferOneClub(false);
          }}
        >Cancel</RACButton>
      </>
    )
  }
  
  const enteredAsCoRenterClubSameStorePopup = () => {
    
      return (
        <>
          <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>This corenter &nbsp;
                                                        {customerInformation?.coCustomerFirstName ? customerInformation?.coCustomerFirstName : ''} &nbsp;
                                                        {customerInformation?.coCustomerLastName ? customerInformation?.coCustomerLastName : ''}          
                                                        &nbsp;can not be used 
                                                        <br/> to initiate the transfer.  Please use the primary borrower&nbsp;   
                                                        {clubTransferData.current.primaryFirstName ? clubTransferData.current.primaryFirstName : ''}&nbsp;
                                                        {clubTransferData.current.primaryLastName ? clubTransferData.current.primaryLastName : ''}
                                                        &nbsp;to perform the transfer </Typography>
          <RACButton
            variant='contained'
            color='primary'
            style={{
              marginTop: '18px',
              padding: '10px 25px',
              marginLeft: '190px',
              marginRight: '10px',
            }}
            onClick={ () => {
              // hard stop 
              setEnteredAsCoRenterClubSameStore(false);
            }}
          >Ok</RACButton>
          <RACButton
            variant="outlined"
            color='primary'
            style={{
              marginTop: '18px',
              marginLeft: '10px',
              padding: '10px 25px',
            }}
            onClick={() => {
              // do not do the transfer 
              setEnteredAsCoRenterClubSameStore(false);
            }}
          >Cancel</RACButton>
        </>
      )
    }
    
  const transferManyStoresClubPopup = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>The primary customer has agreements in multiple stores.  
                                                <br /> Would the customer like to transfer their club to the same store 
                                                <br /> as the agreement, or one of their other stores?</Typography>
        <Grid>
          <Card style={{marginTop: '20px',borderRadius: '15px',marginLeft: '8px'}}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid style={{ width: '44%' }}>
                  <RACSelect
                    
                    loading={stateLoading}
                    inputLabel="Store"
                    inputLabelClassName={classes.AgrTransferReqSearchCriteriaFont}
                    options={clubStoreOptions}
                    defaultValue={selectedClubStore}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      
                      setSelectedClubStore(e.target.value);
                      clubTransferData.current.selectedClubStore = e.target.value;
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
				
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '18px',
            padding: '10px 25px',
            marginLeft: '190px',
            marginRight: '10px',
          }}
          onClick={async () => {            
            if ( await autoTransferClub() ) {
              setTransferManyStoresClub(false);
              settransferTostorePopup(true)
            } else {
              setTransferManyStoresClub(false);
            }
            
          }}
        >Ok</RACButton>
        <RACButton
          variant="outlined"
          color='primary'
          style={{
            marginTop: '18px',
            marginLeft: '10px',
            padding: '10px 25px',
          }}
          onClick={() => {
            // do not do the transfer 
            setTransferManyStoresClub(false);
          }}
        >Cancel</RACButton>
                                            
      </>
    )
  }
  const benefitPlusPopupFn = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>The primary customer has Benefit Plus. You can not transfer this party until you deactivate Benefit Plus</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '20px',
            marginLeft: '240px',
            padding: '12px 30px',
          }}
          onClick={() => {
            history.push({
              pathname: `/payment1/paymentinformation/${customerId}/0`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
          }}
        >Ok</RACButton>
      </>
    )
  }
  const clubTransferConfigurable = () => {
    return (
      <>
        <Typography style={{ "textAlign": "center", "fontSize": "16px" }}>Club Transfer Option has been disabled.</Typography>
        <RACButton
          variant='contained'
          color='primary'
          style={{
            marginTop: '20px',
            marginLeft: '240px',
            padding: '12px 30px',
          }}
          onClick={() => {
            history.push({
              pathname: `/payment1/paymentinformation/${customerId}/0`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
          }}
        >Ok</RACButton>
      </>
    )
  }
  const transferToStorePopupFn = () => {
    return (
      <>
        <Typography style={{ "fontSize": "17px", "textAlign": "center", "marginTop": "-15px" }}>Transfer customer's agreement(s) to store <Typography style={{fontFamily:'OpenSans-bold'}}>#{customerInformation.toStoreNumber}</Typography></Typography>
        <RACButton
          style={{
            marginTop: '18px',
            marginLeft: '117px',
            padding: '10px 25px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            settransferTostorePopup(false)
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '18px',
            padding: '10px 25px',
            marginLeft: '18px',
          }}
          onClick={() => {
            settransferTostorePopup(false);
            transferRequestFn();
          }}
        >
          Yes
        </RACButton>
      </>
    )
  }
  return (
    <>
      <Grid style={{"width":"99%"}}>
        <Grid>
          {hiddenLoader === true ? (
            <Grid
              style={{
                position: 'fixed',
                background: '#f7f5f5',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: 0.6,
                zIndex: 2000,
                textAlign: 'center',
                margin: '0px 0px',
              }}
            >
              <Grid
                style={{
                  display: 'block',
                  position: 'fixed',
                  zIndex: 9999999,
                  top: '40%',
                  right: '50%',
                }}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          ) : null}
          {secondFactorEnabled ? (
            <SecondFactor
              setTwoFactorCancelClick={setTwoFactorCancelClick}
              setTwoFactorCompleted={setTwoFactorCompleted}
              moduleName={moduleName}
              currentRole={currentRole}
            />
          ) : null}
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive}`}
            >
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push({
                    pathname: `/dashboard`,
                  });
                  const navEvent = new PopStateEvent('popstate');
                  window.dispatchEvent(navEvent);
                }}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
              onClick={() => {
                history.push({
                  pathname: `/customer/update/${customerId}/customer`,
                });
                const navEvent = new PopStateEvent('popstate');
                window.dispatchEvent(navEvent);
              }}
              style={{ cursor: 'pointer' }}
            >
              {customerInformation?.customerId == customerId ? customerInformation?.customerName + ' ' + customerInformation?.customerLastName : customerInformation?.coCustomerFirstName + ' ' + customerInformation.coCustomerLastName}
              {' '}
              - {customerId}
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItemActive1}  ${classes.breadcrumbItem} ${classes.breadcrumbArrow}`}
            >
              <a
              >
                Transfer
              </a>
            </ListItem>

          </List>

          <RACModalCard
            isOpen={somethingWentWrongOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={modalCardRadius}
          >
            {somethingWentWrongPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={errorMessagePopupOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={modalCardRadius}
          >
            {errorMessagePopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={coAgreementsPopup}
            borderRadius={modalCardRadius}
            maxWidth='xs'
         >
          {customerOrderPendingAgreementsPopupFn()}
          </RACModalCard>
          <RACModalCard
            isOpen={bothCustomerPresentPopup}
            borderRadius={modalCardRadius}
            maxWidth='xs'
            closeIcon={true}
            onClose={() => {
              setbothCustomerPresentPopup(false)
            }}
          >
            {bothCutomerPresentPopupFn()}
          </RACModalCard>
          <RACModalCard
            isOpen={coCustomerTransferPopup}
            borderRadius={modalCardRadius}
            maxWidth='xs'
            closeIcon={true}
            onClose={() => {
              setcoCustomerTransferPopup(false)
            }}
          >
            {otherRenterTransferPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={coCustomerTransferNoPopup}
            borderRadius={modalCardRadius}
            maxWidth='xs'
            closeIcon={true}
            onClose={() => {
              setcoCustomerTransferNoPopup(false)
            }}>
            {otherCustomerTransferNopopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={transferRequestSuccessPopup}
            maxWidth='xs'
            borderRadius={modalCardRadius}
          >
            {transferRequestSuccessPopupFn()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={clubTransferEnable}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setClubTransferEnable(false)
            }}
          >
            {clubTransferConfigurable()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={benefitPlusPopup}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setbenefitPlusPopup(false)
            }}
          >
            {benefitPlusPopupFn()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={transferManyStoresClub}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setTransferManyStoresClub(false)
            }}
          >
            {transferManyStoresClubPopup()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={enteredAsCoRenterClubSameStore}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setEnteredAsCoRenterClubSameStore(false)
            }}
          >
            {enteredAsCoRenterClubSameStorePopup()}
          </RACModalCard>
          
          <RACModalCard
            closeIcon={true}
            isOpen={autoTransferOneClub}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setAutoTransferOneClub(false)
            }}
          >
            {autoTransferOneClubPopup()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={autoTransferManyClub}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setAutoTransferManyClub(false)
            }}
          >
            {autoTransferManyClubPopup()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            isOpen={autoCancelOneStoreClub}
            borderRadius={modalCardRadius}
            maxWidth='sm'
            onClose={() => {
              setAutoCancelOneStoreClub(false)
            }}
          >
            {autoCancelOneStoreClubPopup()}
          </RACModalCard>
          <RACModalCard
            closeIcon={true}
            borderRadius={modalCardRadius}
            isOpen={transferTostorePopup}
            maxWidth='xs'
            onClose={() => {
              settransferTostorePopup(false);
            }}
          >
            {transferToStorePopupFn()}
          </RACModalCard>
          <Grid style={{ "marginRight": "-5px" }}>
            <Typography variant="h5" className={classes.TransferAgreementHeading}>
              Transfer Agreements
            </Typography>
            <Card  style={{ height: '324px', width: '100%',marginTop: '20px',borderRadius: '15px',marginLeft: '8px',marginRight:'-7px' }}>
              <CardContent>
                <Grid className={gridClass.AgrTrasnferRequestTable}>
                  <RACTable
                    renderTableHead={renderTableHeadFn}
                    renderTableContent={renderTableContentFn}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {searchCriteriaHidden && !noRecords ? <>
            <Grid>
              <Typography variant="h5" className={classes.TransferAgreementHeading}>
                Search Criteria
              </Typography>
              <Card style={{marginTop: '20px',borderRadius: '15px',marginLeft: '8px',marginRight:'-7px'}}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={1}>
                      <RACTextbox
                        inputlabel="Store#"
                        name="storeNumber"
                        type='number'
                        value={transferRequestValues.storeNumbers}
                        className={classes.AgrTrRecStore}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        maxlength={5}
                        OnChange={(e: any) => {
                          handleChangeInput(e);
                        }}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RACTextbox
                        inputlabel="Phone Number"
                        type='number'
                        name='phoneNumber'
                        maxlength={10}
                        value={transferRequestValues.phoneNumber}
                        className={classes.AgrTrRecPhnNum}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        OnChange={(e: any) => handleChangeInput(e)}
                        isCurrency={false}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RACTextbox
                        inputlabel="Address Line 1"
                        name='addressLine1'
                        value={transferRequestValues.addressLine1}
                        className={classes.AgrTrRecAddLine1}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        OnChange={(e: any) => {
                          handleChangeInput(e);
                        }}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RACTextbox
                        inputlabel="Address Line 2"
                        name='addressLine2'
                        value={transferRequestValues.addressLine2}
                        className={classes.AgrTrRecAddLine2}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        OnChange={(e: any) => {
                          handleChangeInput(e);
                        }}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <RACTextbox
                        inputlabel="Zip"
                        type='text'
                        name='zipCode'
                        maxlength={10}
                        isCurrency={false}
                        value={transferRequestValues.zip}
                        className={classes.AgrTrRecZip}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        OnChange={(e: any) => handleChangeInput(e)}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <RACTextbox
                        type='text'
                        inputlabel="City"
                        name='city'
                        value={transferRequestValues.city}
                        className={classes.AgrTrRecCity}
                        inputLabelClassname={classes.AgrTransferReqSearchCriteriaFont}
                        OnChange={(e: any) => {
                          const onlyLetters = /^[A-Za-z]+$/;
                          if (!onlyLetters.test(e.target.value)) {
                            e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
                          } 
                          handleChangeInput(e);
                        }}
                        OnKeydown={(e) => EnterOnClick(e)}
                      />
                    </Grid>
                    <Grid item md={1} className={classes.transferStateDropDown}>

                      <RACSelect

                        loading={stateLoading}
                        inputLabel="State"
                        inputLabelClassName={classes.AgrTransferReqSearchCriteriaFont}
                        options={State}
                        defaultValue={transferRequestValues.stateId}
                        onChange={(e: any) => {
                          e.target.name = "state";
                          handleChangeInput(e);

                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RACButton variant="outlined" color="primary"
                        style={{ "marginLeft": "10px", "marginTop": "31px", padding: '5px 20px' }}
                        onClick={() => {
                          handleClear()
                        }}
                      >
                        Clear
                      </RACButton>
                      <RACButton variant="contained" color="primary" style={{ "marginLeft": "10px", "marginTop": "31px", padding: '5px 12px' }}
                        startIcon={<SearchIcon style={{ "marginTop": "0.2rem" }} />}
                        disabled={transferRequestValues.storeNumbers || transferRequestValues.phoneNumber || transferRequestValues.addressLine1 || transferRequestValues.addressLine2 || transferRequestValues.zip || transferRequestValues.city || transferRequestValues.stateId ? false : true}
                        onClick={() => {
                          getStoreFn()
                        }}
                      >
                        Search
                      </RACButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </> : null}
          {searchResultHidden ?
            <>
              <Grid>
                <Typography variant="h5" className={classes.TransferAgreementHeading}>
                  Search Results
                </Typography>
                {searchResultLoader ? <>
                  <Card  style={{ height: '250px', width: '100%',marginTop: '20px',borderRadius: '15px',marginLeft: '8px',marginRight:'-7px'}}>
                    <CardContent>
                      <Grid style={{ "marginLeft": "40rem", "marginTop": "94px" }}>
                        <CircularProgress />
                      </Grid>
                    </CardContent>
                  </Card>
                </> :
                  <>
                    <Card style={{ height: '324px',marginTop: '20px',borderRadius: '15px',marginLeft: '8px',marginRight:'-7px' }}>
                      <CardContent>
                        <Grid className={gridClass.AgrTrasnferRequestTable}>
                          <RACTable
                            renderTableHead={renderSearchResultTableHeadFn}
                            renderTableContent={renderSearchResultTableContentFn}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                }
              </Grid>
            </>
            : null}
        </Grid>
        <Grid className={classes.footerGrid}>
          <Grid className={classes.footerFixed}>

            <RACButton
              variant="outlined"
              color="primary"
              style={{ marginTop: '15px', float: 'left' }}
              onClick={() => {
                history.push({
                  pathname: `/customer/update/${customerId}/customer`,
                });
                const navEvent = new PopStateEvent('popstate');
                window.dispatchEvent(navEvent);
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              style={{ float: 'right', marginTop: '15px' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setsecondFactorEnabled(true);
              }}

              disabled={(searchresultRadio.includes(true) && customerInformation.toStoreNumber !== window.sessionStorage.getItem('storeNumber')) ? false : true}
            >
              Transfer
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
