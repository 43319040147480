/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
//PS_SO-AD-1
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import { AgreementContext } from '../../../context/AgreementContext';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import {
  Grid,
  Typography,
  RACButton,
  RACRadio,
  RACModalCard,
  RACTextbox,
  RACCheckBox,
  CircularProgress,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { autoPayStyles } from '../../../JSstyles/autoPayStyles';
import SwitchOutGeneralInformation from './SwitchOutGeneralInformation';
import TotalPaymentCard from './TotalPaymentCard';
import GridComponent from './GridComponent';
import { useParams } from 'react-router-dom';
import {
  UpdateAgreement,
  GetAgreementCustomer,
  GenerateAgreementDoc,
  SendAutopayText,
  insertAgreementLog,
} from '../../../api/user';
import CustomerSignature from '../../Shared/CustomerSignature';

interface Params {
  customerId: string;
  agreementId: string;
}
export default function SwitchOutAgreementDetails(props: any) {
  //PS_SO-AD-2
  const classes = agreementGlobalStyles();
  const autoPayClassName = autoPayStyles();
  //PS_SO-AD-3
  const history = useHistory();
  //PS_SO-AD-4
  const { customerId, agreementId } = useParams<Params>();
  //PS_SO-AD-5
  const {
    agreementDetailsData,
    setAgreementDetailsData,
    switchOutData,
    addedItem,
    CustomerInfo,
    setagreementCreateStatus,
    pendingSOAgr,
    setpendingSOAgr,
    pendingSOpreInvData,
    setpendingSOpreInvData,
    customerSignData,
    setcustomerSignData,
    entireAgrInfoResponse,
    setentireAgrInfoResponse,
    autoPayStatus,
    retentionActivityCall,
  } = useContext(SwitchOutAgreementContext);
  //PS_SO-AD-6
  // const [itemSearchValues, setitemSearchValues] = useState(
  //   agreementDetailsContext
  // );
  //PS_SO-AD-7
  const itemGridHeadValues = [
    'Item #',
    'Item Description',
    'Tag Rate',
    'Tag term',
    'Tag Total',
    'Agr Rate',
    'Agr Term',
    'Agr Total',
    'Inv Depr%',
  ];
  //PS_SO-AD-8
  const generalInfoObj = {
    agreementType: '',
    paySchedule: '',
    agreementSourceOption: [{ value: '', label: 'Select' }],
    agreementSourceValue: '',
    scheduleOption: [{ value: '', label: 'Select' }],
    scheduleValue: '',
    deliveryChargeOption: [{ value: '', label: 'Select' }],
    deliveryChargeValue: '',
    dueDate: '',
    daysExtensionOption: [{ value: '', label: 'Select' }],
    daysExtensionValue: '',
    promoCodeOption: [{ value: '', label: 'Select' }],
    promoCodeValue: '',
    optServicesOption: [{ value: '', label: 'Select' }],
    optServicesValue: '',
    rangeValue: [{ value: '', label: 'Select' }],
    termValue: '',
    salesPersonOption: [{ value: '', label: 'Select' }],
    salesPerson: '',
  };
  //PS_SO-AD-10
  const totalPayementObj = {
    initialPaymentValue: '',
    regularPaymentTerm: '',
    regularPaymentValue: '',
    finalPaymentValue: '',
    totalPaymentCount: '',
    sacAmountValue: '',
    agreementTotal: '',
  };
  const autopayInputObj = {
    autoPaySubmitDisable: true,
    autopayChkBox: false,
  };
  //PS_SO-AD-11
  const [prevItemGridBodyValues, setprevItemGridBodyValues]: any = useState([]);
  //PS_SO-AD-12
  const [newItemGridBodyValues, setnewItemGridBodyValues]: any = useState([]);
  //PS_SO-AD-13
  const [generalInfoValue, setgeneralInfoValue] = useState(generalInfoObj);
  //PS_SO-AD-14
  const [salesPersonDrpdwnVal, setsalesPersonDrpdwnVal]: any = useState([]);
  //PS_SO-AD-15
  const [maualSignReasonDrpdwnVal, setmaualSignReasonDrpdwnVal] = useState([
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ]);
  //PS_SO-AD-16
  const [totalPaymentCardValue, settotalPaymentCardValue] =
    useState(totalPayementObj);
  //PS_SO-AD-17
  const [somethingWentWrong, setsomethingWentWrong] = useState(false);
  //PS_SO-AD-18
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [errorMessageOpen, seterrorMessageOpen] = useState(false);
  //PS_SO-AD-19
  const [nextBtnDisabled, setnextBtnDisabled] = useState(true);
  //PS_SO-AD-20
  const [editDisableMode, seteditDisableMode] = useState(false);
  const [updateAgreementReq, setupdateAgreementReq] = useState({});
  const [customerSignEnabled, setcustomerSignEnabled] = useState(false);
  const [customersArray, setcustomersArray]: any = useState([
    {
      customerId: '',
      customerName: '',
      phoneNumber: '',
      emailId: '',
      priority: 1,
    },
  ]);
  const [generateDocReq, setgenerateDocReq] = useState({});
  const [soProcessingOpen, setsoProcessingOpen] = useState(false);
  const [soAgreementDone, setsoAgreementDone] = useState(false);
  const [docGenerationDone, setdocGenerationDone] = useState(false);
  const [previousBtnDisabled, setpreviousBtnDisabled] = useState(false);
  const [autoPayOpen, setautoPayOpen] = useState(false);
  const [autopayInput, setautopayInput] = useState(autopayInputObj);
  const [autopayCustomerInfo, setautopayCustomerInfo] = useState([
    {
      customerId: '',
      customerName: '',
      phoneNumber: '',
      emailId: '',
      priority: 1,
    },
  ]);
  const [updateAgrErrorMsg, setupdateAgrErrorMsg] = useState('');

  const customerSignUsingField = 'SwitchOut';
  useEffect(() => {
    //PS_SO-AD-28
    window.scrollTo(0, 0);
    props.setItemSearchCompleted(true);
    console.log('pendingSOAgr', pendingSOAgr);
    console.log('Addeditem innn', addedItem);
    if (!pendingSOAgr) {
      const TempUpdateAgrReq: any = updateAgreementReq;
      if (agreementDetailsData !== undefined && switchOutData !== undefined) {
        const switchOutVal = agreementDetailsData;
        const prevItemVal = switchOutData;
        console.log('switchOutVal', switchOutVal);
        console.log('prevItemVal', prevItemVal);
        const RawDueDate = switchOutVal.agreementSchedule.dueDate;
        const RawDueDateArr = RawDueDate.split('/');
        console.log('DueDateArray--->', RawDueDateArr);
        const FormatedDuedate =
          RawDueDateArr[2] + '-' + RawDueDateArr[0] + '-' + RawDueDateArr[1];
        console.log('FormatedDuedate', FormatedDuedate);

        let tempTermValue: any = '';
        if (switchOutVal.agreementSchedule.schedule === 'MON') {
          tempTermValue = switchOutVal.paymentSchedule.monthlyTerm;
        } else if (switchOutVal.agreementSchedule.schedule === 'WK') {
          tempTermValue = switchOutVal.paymentSchedule.weeklyTerm;
        } else if (switchOutVal.agreementSchedule.schedule === 'BIWK') {
          tempTermValue = switchOutVal.paymentSchedule.biweeklyTerm;
        } else if (switchOutVal.agreementSchedule.schedule.includes('SEMI')) {
          tempTermValue = switchOutVal.paymentSchedule.semimonthlyTerm;
        }
        setgeneralInfoValue({
          ...generalInfoValue,
          dueDate: FormatedDuedate,
          agreementSourceOption: switchOutVal.agreementOption.agreementSource,
          agreementSourceValue:
            switchOutVal.generalInformation.agreementSourceValue,
          scheduleOption: switchOutVal.agreementOption.schedule,
          scheduleValue: switchOutVal.agreementSchedule.schedule,
          deliveryChargeOption: switchOutVal.agreementOption.deliveryCharge,
          deliveryChargeValue:
            switchOutVal.generalInformation.deliveryChargeValue,
          promoCodeOption: switchOutVal.agreementOption.promoCode,
          promoCodeValue: switchOutVal.generalInformation.promoCodeValue,
          optServicesOption: switchOutVal.agreementOption.optServices,
          optServicesValue: switchOutVal.generalInformation.optServicesValue,
          daysExtensionOption: switchOutVal.agreementOption.daysExtension,
          daysExtensionValue:
            switchOutVal.generalInformation.daysExtensionValue,
          paySchedule: switchOutVal.employerPayschedule,
          termValue: tempTermValue,
        });
        if (switchOutVal.agreementTotalPayment !== undefined) {
          settotalPaymentCardValue({
            ...totalPaymentCardValue,
            initialPaymentValue:
              switchOutVal.agreementTotalPayment.initialPaymentTotal,
            regularPaymentValue:
              switchOutVal.agreementTotalPayment.regularPaymentsAmount,
            regularPaymentTerm:
              switchOutVal.agreementTotalPayment.regularPaymentsCount,
            finalPaymentValue: switchOutVal.agreementTotalPayment.finalPayment,
            totalPaymentCount:
              switchOutVal.agreementTotalPayment.totalPaymentsCount,
            sacAmountValue: switchOutVal.agreementTotalPayment.sacTotal,
            agreementTotal: switchOutVal.agreementTotalPayment.agreementTotal,
          });
        }

        const switchOutItemPricing = prevItemVal.itemPricing[0];
        const switchOutAgrPricing = prevItemVal.agreementPricing;
        console.log('switchOutItemPricing', switchOutItemPricing);
        console.log('switchOutAgrPricing', switchOutAgrPricing);
        TempUpdateAgrReq.action = 'ESO';
        TempUpdateAgrReq.switchOut = {
          previousInventoryId: parseInt(switchOutItemPricing.inventoryId),
        };
        TempUpdateAgrReq.agreementId = parseInt(agreementId);
        setprevItemGridBodyValues([
          {
            itemNo: switchOutItemPricing.inventoryNumber,
            itemDesc: switchOutItemPricing.inventoryName,
            tagRate: switchOutItemPricing.weeklyRate,
            tagTerm: switchOutItemPricing.weeklyTerm,
            tagTotal: switchOutItemPricing.tagTotal,
            agrRate: switchOutItemPricing.agreementRate,
            agrTerm: switchOutAgrPricing.agreementTerm,
            agrTotal: switchOutItemPricing.agreementTotal,
            invDepr: switchOutItemPricing.inventoryDepreciation,
          },
        ]);
      }
      if (addedItem !== undefined) {
        setnewItemGridBodyValues([
          {
            itemNo:
              addedItem.itemPricing[0].inventoryNumber != undefined
                ? addedItem.itemPricing[0].inventoryNumber
                : '',
            itemDesc:
              addedItem.itemPricing[0].inventoryName != undefined
                ? addedItem.itemPricing[0].inventoryName
                : '',
            tagRate: addedItem.itemPricing[0].weeklyRate,
            tagTerm: addedItem.itemPricing[0].weeklyTerm,
            tagTotal: addedItem.itemPricing[0].tagTotal,
            agrRate: addedItem.itemPricing[0].agreementRate,
            agrTerm: addedItem.agreementPricing.agreementTerm,
            agrTotal: addedItem.itemPricing[0].agreementTotal,
            invDepr: addedItem.itemPricing[0].inventoryDepreciation,
          },
        ]);
        TempUpdateAgrReq.switchOut.newInventoryInfo = {
          inventoryId: parseInt(addedItem.itemPricing[0].inventoryId),
          inventoryPriceId: parseInt(addedItem.itemPricing[0].inventoryPriceId),
        };
      }
      console.log('updateAgreement First Req', TempUpdateAgrReq);
      setupdateAgreementReq(TempUpdateAgrReq);
    } else if (pendingSOAgr) {
      setnextBtnDisabled(false);
      setcustomersArray(customerSignData);
      setdocGenerationDone(true);
      if (agreementDetailsData != undefined && switchOutData != undefined) {
        const switchOutVal = agreementDetailsData;
        const prevItemVal = switchOutData;
        console.log('switchOutVal', switchOutVal);
        console.log('prevItemVal', prevItemVal);
        const RawDueDate = switchOutVal.agreementSchedule.dueDate;
        const RawDueDateArr = RawDueDate.split('/');
        console.log('DueDateArray--->', RawDueDateArr);
        const FormatedDuedate =
          RawDueDateArr[2] + '-' + RawDueDateArr[0] + '-' + RawDueDateArr[1];
        console.log('FormatedDuedate', FormatedDuedate);

        let tempTermValue: any = '';
        if (switchOutVal.agreementSchedule.schedule === 'MON') {
          tempTermValue = switchOutVal.paymentSchedule.monthlyTerm;
        } else if (switchOutVal.agreementSchedule.schedule === 'WK') {
          tempTermValue = switchOutVal.paymentSchedule.weeklyTerm;
        } else if (switchOutVal.agreementSchedule.schedule === 'BIWK') {
          tempTermValue = switchOutVal.paymentSchedule.biweeklyTerm;
        } else if (switchOutVal.agreementSchedule.schedule.includes('SEMI')) {
          tempTermValue = switchOutVal.paymentSchedule.semimonthlyTerm;
        }
        setgeneralInfoValue({
          ...generalInfoValue,
          dueDate: FormatedDuedate,
          agreementSourceOption: switchOutVal.agreementOption.agreementSource,
          agreementSourceValue:
            switchOutVal.generalInformation.agreementSourceValue,
          scheduleOption: switchOutVal.agreementOption.schedule,
          scheduleValue: switchOutVal.agreementSchedule.schedule,
          deliveryChargeOption: switchOutVal.agreementOption.deliveryCharge,
          deliveryChargeValue:
            switchOutVal.generalInformation.deliveryChargeValue,
          promoCodeOption: switchOutVal.agreementOption.promoCode,
          promoCodeValue: switchOutVal.generalInformation.promoCodeValue,
          optServicesOption: switchOutVal.agreementOption.optServices,
          optServicesValue: switchOutVal.generalInformation.optServicesValue,
          daysExtensionOption: switchOutVal.agreementOption.daysExtension,
          daysExtensionValue:
            switchOutVal.generalInformation.daysExtensionValue,
          paySchedule: switchOutVal.employerPayschedule,
          termValue: tempTermValue,
          salesPersonOption: switchOutVal.agreementOption.salesPerson,
          salesPerson: switchOutVal.generalInformation.salesPersonValue,
        });
        if (switchOutVal.agreementTotalPayment !== undefined) {
          settotalPaymentCardValue({
            ...totalPaymentCardValue,
            initialPaymentValue:
              switchOutVal.agreementTotalPayment.initialPaymentTotal,
            regularPaymentValue:
              switchOutVal.agreementTotalPayment.regularPaymentsAmount,
            regularPaymentTerm:
              switchOutVal.agreementTotalPayment.regularPaymentsCount,
            finalPaymentValue: switchOutVal.agreementTotalPayment.finalPayment,
            totalPaymentCount:
              switchOutVal.agreementTotalPayment.totalPaymentsCount,
            sacAmountValue: switchOutVal.agreementTotalPayment.sacTotal,
            agreementTotal: switchOutVal.agreementTotalPayment.agreementTotal,
          });
        }
      }
      if (addedItem !== undefined) {
        setnewItemGridBodyValues([
          {
            itemNo:
              addedItem.itemPricing[0].inventoryNumber != undefined
                ? addedItem.itemPricing[0].inventoryNumber
                : '',
            itemDesc:
              addedItem.itemPricing[0].inventoryName != undefined
                ? addedItem.itemPricing[0].inventoryName
                : '',
            tagRate: addedItem.agreementPricing.tagRate,
            tagTerm: addedItem.agreementPricing.tagTerm,
            tagTotal: addedItem.agreementPricing.tagTotal,
            agrRate: addedItem.agreementPricing.agreementRate,
            agrTerm: addedItem.agreementPricing.agreementTerm,
            agrTotal: addedItem.agreementPricing.agreementTotal,
            invDepr: addedItem.agreementPricing.agreementInventoryDepreciation,
          },
        ]);
      }
      if (pendingSOpreInvData != undefined) {
        setprevItemGridBodyValues([
          {
            itemNo: pendingSOpreInvData[0].inventoryNumber,
            itemDesc: pendingSOpreInvData[0].description,
            tagRate: pendingSOpreInvData[0].tagRate,
            tagTerm: pendingSOpreInvData[0].tagTerm,
            tagTotal: pendingSOpreInvData[0].tagTotal,
            agrRate: pendingSOpreInvData[0].agreementRate,
            agrTerm: pendingSOpreInvData[0].agreementTerm,
            agrTotal: pendingSOpreInvData[0].agreementTotal,
            invDepr: pendingSOpreInvData[0].inventoryDepreciation,
          },
        ]);
      }
      seteditDisableMode(true);
    }
  }, []);
  useEffect(() => {
    const GetCustomerInfo = async () => {
      const TempGenerateReq: any = {};
      const Customerslist = await GetAgreementCustomer(parseInt(agreementId));
      console.log('Customerslist', Customerslist);
      if (Customerslist.status === 200) {
        const AgreementCustomers = Customerslist.data;
        console.log('AgreementCustomers', AgreementCustomers);
        const Customerdata: any = AgreementCustomers.customer;
        const CoCustomer: any = AgreementCustomers.coCustomer;
        if (CoCustomer.length === 0) {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId,
              customerName: Customerdata[0].customerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          setcustomersArray(CustomerObj);
          setautopayCustomerInfo(CustomerObj);
          TempGenerateReq.signers = CustomerObj;
        } else {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId,
              customerName: Customerdata[0].customerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId,
              customerName: CoCustomer[0].customerName,
              phoneNumber: CoCustomer[0]?.phoneNumbers ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) : CoCustomer[0]?.phoneNumber : CoCustomer[0]?.phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          setcustomersArray(CustomerObj);
          setautopayCustomerInfo(CustomerObj);
          TempGenerateReq.signers = CustomerObj;
        }
      }
      TempGenerateReq.identifier = parseInt(agreementId);
      TempGenerateReq.identifierType = 'AGR';
      TempGenerateReq.documentType = ['SWA'];
      TempGenerateReq.isRemote = false;
      TempGenerateReq.manualSignatureReasonId = null;
      TempGenerateReq.returnURL = window.location.origin;
      TempGenerateReq.signatureType = 1;
      TempGenerateReq.signingUrlRequired = false;
      console.log('TempGenerateReq', TempGenerateReq);
      setgenerateDocReq(TempGenerateReq);
    };
    GetCustomerInfo();
  }, []);
  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber
      }
    }
    return undefined;
  }
  const previousBtnClick = () => {
    history.push({
      pathname: `/agreement/switchout/itemsearch/${customerId}/${agreementId}`,
    });
    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };
  const generalInfoChanged = (value: any) => {
    console.log('generalInfoChanged', value);
    const TempUpdateReq: any = updateAgreementReq;
    TempUpdateReq.switchOut.salesPersonId = value?.toString();
    setupdateAgreementReq(TempUpdateReq);
  };
  const storePendingData = () => {
    const TempArr = [
      {
        inventoryNumber: prevItemGridBodyValues[0].itemNo,
        description: prevItemGridBodyValues[0].itemDesc,
        tagRate: prevItemGridBodyValues[0].tagRate,
        tagTerm: prevItemGridBodyValues[0].tagTerm,
        tagTotal: prevItemGridBodyValues[0].tagTotal,
        agreementRate: prevItemGridBodyValues[0].agrRate,
        agreementTerm: prevItemGridBodyValues[0].agrTerm,
        agreementTotal: prevItemGridBodyValues[0].agrTotal,
        inventoryDepreciation: prevItemGridBodyValues[0].invDepr,
      },
    ];
    setpendingSOpreInvData(TempArr);
  };
  const retentionCheck = () => {
    if (retentionActivityCall && retentionActivityCall == '1') {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const formattedDate = year + '-' + month + '-' + day;
      const agreementLogReq = {
        agreementActivities: [
          {
            activityDate: formattedDate,
            agreementId: String(agreementId),
            activityDescription: 'Agr Retention Switchout Performed',
            agreementActivityType: 'AGMRETSWP',
          },
        ],
      };
      insertAgreementLog(agreementLogReq);
    }
  };
  //PS_SO-AD-31
  const nextBtnClick = async () => {
    if (!pendingSOAgr) {
      if (!soAgreementDone) {
        console.log('Next Btn clicked');
        console.log('updateAgreementRequest', updateAgreementReq);
        setsoProcessingOpen(true);
        retentionCheck();
        const UpdateAgreementRes: any = await UpdateAgreement(
          updateAgreementReq
        );
        console.log('UpdateAgreementRes', UpdateAgreementRes);
        console.log('UpdateAgreementData', UpdateAgreementRes.data);
        if (UpdateAgreementRes.status == 200) {
          if (entireAgrInfoResponse != undefined) {
            const TempAgrInfoRes: any = entireAgrInfoResponse;
            TempAgrInfoRes.agreementWorkflow.isSwitchoutEnabled = '1';
            setentireAgrInfoResponse(TempAgrInfoRes);
          }
          setpreviousBtnDisabled(true);
          setsoAgreementDone(true);
          setsoProcessingOpen(false);
          autoPayStatus == '0'
            ? setautoPayOpen(true)
            : setcustomerSignEnabled(true);
          seteditDisableMode(true);
          setagreementCreateStatus({ agreementCreatedStatus: true });
          //instant pending scenario handling
          setpendingSOAgr(true);
          storePendingData();
          setcustomerSignData(customersArray);
          console.log('generateDocReq before call', generateDocReq);
          const generateAgreementDocRes = await GenerateAgreementDoc(
            generateDocReq
          );
          console.log('generateAgreementDocRes', generateAgreementDocRes);
          setdocGenerationDone(true);
          setpreviousBtnDisabled(false);
        } else if (UpdateAgreementRes.status == 400) {
          setsoProcessingOpen(false);
          seterrorMessageOpen(true);
          const ErrorMessage = UpdateAgreementRes.data?.errors[0]?.error;
          setupdateAgrErrorMsg(ErrorMessage);
        } else {
          setsoProcessingOpen(false);
          setsomethingWentWrongOpen(true);
        }
      } else {
        autoPayStatus == '0'
          ? setautoPayOpen(true)
          : setcustomerSignEnabled(true);
      }
    } else if (customerSignData != undefined) {
      setcustomerSignEnabled(true);
    } else {
      history.push({
        pathname: `/agreement/switchout/delivery/${customerId}/${agreementId}`,
      });
      // communicate to Routes that URL has changed
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const SwitchOutProcessingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <Typography className={classes.formLabel}>
            Updating the agreement
          </Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const customerSignatureCompletedFn = () => {
    console.log('Customer sign completed');
    setpendingSOAgr(true);
    storePendingData();
    setcustomerSignData(undefined);
    setcustomerSignEnabled(false);
    history.push({
      pathname: `/agreement/switchout/delivery/${customerId}/${agreementId}`,
    });
    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };
  const customerSignatureCanceledFn = () => {
    console.log('Customer sign canceled');
    setcustomerSignEnabled(false);
  };
  const autoPayCloseClick = () => {
    try {
      setautoPayOpen(false);
    } catch {
      throw Error;
    }
  };
  const autoPaySubmitClick = () => {
    try {
      const AutopayReq: any = {};
      AutopayReq.agreementIds = [parseInt(agreementId)];
      const AutoPayResponse: any = SendAutopayText(AutopayReq);
      console.log('AutoPayResponse', AutoPayResponse);
      setautoPayOpen(false);
      setcustomerSignEnabled(true);
    } catch {
      setautoPayOpen(false);
      setcustomerSignEnabled(true);
    }
  };
  const autoPayNotnowClick = () => {
    try {
      setautoPayOpen(false);
      setcustomerSignEnabled(true);
    } catch {
      throw Error;
    }
  };
  const autopayChkboxAction = () => {
    try {
      if (autopayInput.autopayChkBox) {
        setautopayInput({
          ...autopayInput,
          autopayChkBox: false,
          autoPaySubmitDisable: true,
        });
      } else {
        setautopayInput({
          ...autopayInput,
          autopayChkBox: true,
          autoPaySubmitDisable: false,
        });
      }
    } catch {
      throw Error;
    }
  };
  const somethingWentWrongPopup = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setsomethingWentWrongOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const errorMessagePopup = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {updateAgrErrorMsg}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => seterrorMessageOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const autopayPopupup = () => {
    return (
      <Grid>
        {autopayCustomerInfo[0].phoneNumber !== '' ? (
          <Grid>
            <Grid className={autoPayClassName.autoPayContainer}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  className={`${autoPayClassName.autoPayTitle} ${classes.textCenter} ${classes.mb3}`}
                >
                  Discuss the benefits of Autopay and enroll your customer today
                </Grid>

                <Grid
                  container
                  className={`${classes.mb3} ${classes.justifyCenter}`}
                >
                  <Grid item lg={10}>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        className={`${autoPayClassName.autoPayValue} ${classes.me2}`}
                      >
                        1
                      </Typography>
                    </Grid>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                      >
                        Convenience
                      </Typography>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                      >
                        Save Customer Time
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={`${classes.mb3} ${classes.justifyCenter}`}
                >
                  <Grid item lg={10}>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        className={`${autoPayClassName.autoPayValue} ${classes.me2}`}
                      >
                        2
                      </Typography>
                    </Grid>
                    <Grid xs="auto" className={classes.floatLeft}>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                      >
                        Zero Fees
                      </Typography>
                      <Typography
                        variant="caption"
                        className={`${autoPayClassName.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                      >
                        Avoid convenience/phone fees{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={`${autoPayClassName.autoPayFormCheck} ${classes.mb3}`}
                >
                  <RACCheckBox
                    className={`${classes.floatLeft} ${classes.textCenter}`}
                    onClick={() => autopayChkboxAction()}
                    checked={autopayInput.autopayChkBox}
                    label="Customer has verbally consented to receive this text message"
                  />
                </Grid>
                <Typography
                  className={`${autoPayClassName.autoPayPrimaryRenter} ${classes.my3}`}
                >
                  Autopay will be associated with the Primary Renter
                </Typography>
                <Grid container className={autoPayClassName.justifycenter}>
                  <Grid item md={8}>
                    <Grid
                      className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
                      style={{ width: '400px' }}
                    >
                      <Typography
                        variant="caption"
                        className={`${classes.me3} ${autoPayClassName.autoPayTxtStyle}`}
                      >
                        {' '}
                        Customer Name
                      </Typography>
                      <Typography
                        variant="caption"
                        className={autoPayClassName.autoPayLabelTxt}
                      >
                        {' '}
                        {customersArray[0].customerName}
                      </Typography>
                    </Grid>
                    <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                      <Typography
                        variant="caption"
                        className={`${classes.me3} ${autoPayClassName.autoPayTxtStyle}`}
                      >
                        Phone Number
                      </Typography>
                      <Typography
                        variant="caption"
                        className={autoPayClassName.autoPayLabelTxt}
                      >
                        {' '}
                        {autopayCustomerInfo[0].phoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              className={`${classes.py4} ${classes.textCenter}`}
            >
              <RACButton
                variant="outlined"
                color="primary"
                className={classes.mx1}
                onClick={() => autoPayNotnowClick()}
              >
                Not now
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                className={classes.mx1}
                disabled={autopayInput.autoPaySubmitDisable}
                onClick={() => autoPaySubmitClick()}
              >
                Send Text at Payment
              </RACButton>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid>
              <Grid className={classes.textCenter}>
                <Typography className={classes.formLabel}>
                  {' '}
                  The TXT to Autopay signup functionality is not available
                  without a Cell Phone #
                </Typography>
                <Typography className={classes.formLabel}>
                  {' '}
                  Please add a Cell Phone # before attempting to send an AutoPay
                  signup text
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
              >
                <RACButton
                  className={classes.mx1}
                  variant="contained"
                  color="primary"
                  onClick={() => autoPayNotnowClick()}
                >
                  Ok
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <Grid>
      {!somethingWentWrong ? (
        <Grid style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          <RACModalCard
            isOpen={somethingWentWrongOpen}
            closeIcon={false}
            maxWidth="xs"
            children={somethingWentWrongPopup()}
            borderRadius={'20px !important'}
          />
          <RACModalCard
            isOpen={errorMessageOpen}
            closeIcon={false}
            maxWidth="xs"
            children={errorMessagePopup()}
            borderRadius={'20px !important'}
          />
          <RACModalCard
            isOpen={soProcessingOpen}
            closeIcon={false}
            maxWidth="xs"
            children={SwitchOutProcessingPopup()}
            borderRadius={'20px !important'}
          />
          <RACModalCard
            isOpen={autoPayOpen}
            closeIcon={true}
            onClose={() => autoPayCloseClick()}
            maxWidth="sm"
            children={autopayPopupup()}
            borderRadius={'20px !important'}
          />
          {customerSignEnabled ? (
            <CustomerSignature
              customerSignUsingField="agreementDetails"
              customerSignUsingModule="switchOut"
              identifier={parseInt(agreementId)}
              customersArr={customersArray}
              manualSignOptions={maualSignReasonDrpdwnVal}
              documentTypeArr={['SWA']}
              customerSignatureCompletedFn={() =>
                customerSignatureCompletedFn()
              }
              customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
              documentGenerateCompleted={docGenerationDone}
            />
          ) : null}
          <GridComponent
            throwStyle={classes}
            itemGridHeadValues={itemGridHeadValues}
            itemGridBodyValues={prevItemGridBodyValues}
            gridName="Previous Item"
          />
          <GridComponent
            throwStyle={classes}
            itemGridHeadValues={itemGridHeadValues}
            itemGridBodyValues={newItemGridBodyValues}
            gridName="New Item"
          />
          <SwitchOutGeneralInformation
            throwStyle={classes}
            setnextBtnDisabled={setnextBtnDisabled}
            editDisableMode={editDisableMode}
            generalInfoValue={generalInfoValue}
            salesPersonDrpdwnVal={salesPersonDrpdwnVal}
            generalInfoChanged={generalInfoChanged}
            pendingSOAgr={pendingSOAgr}
          />
          <TotalPaymentCard
            throwStyle={classes}
            totalPaymentCardValue={totalPaymentCardValue}
          />
          <Grid className={classes.fixedBottom}>
            <RACButton
              className={`${classes.mx1} ${classes.floatLeft}`}
              variant="outlined"
              color="primary"
              onClick={() => previousBtnClick()}
              disabled={previousBtnDisabled}
            >
              Previous
            </RACButton>

            <RACButton
              className={`${classes.me2} ${classes.floatRight}`}
              variant="contained"
              color="primary"
              onClick={() => nextBtnClick()}
              disabled={nextBtnDisabled}
            >
              Next
            </RACButton>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          {' '}
          <div style={{ marginTop: '20%', marginLeft: '40%' }}>
            <Alerticon></Alerticon>
            Something went wrong!
          </div>
        </Grid>
      )}
    </Grid>
  );
}
