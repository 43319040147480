/* eslint-disable */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { agrGeneralInfoStyles } from '../../../JSstyles/agrGeneralInfoStyles';
import { paymentCost } from '../../../JSstyles/paymentCost';
import {
  Grid,
  Typography,
  RACSelect,
  RACButton,
  RACDatePicker,
  Card,
  CardContent,
  CircularProgress,
  RACModalCard,
  RACCOLOR,
} from '@rentacenter/racstrap';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import CurrencyInput from 'react-currency-input-field';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import VoidAgreement from '../../Rental/AddCoCustomer/voidagreement';
import {
  getPriceQuote,
  GetEmployeeID,
  getAgreementInfo,
  GetRole,
  CreateAgreement,
  GenerateAgreementDoc,
} from '../../../api/user';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { PromoAgreementContext } from '../../../context/PromoAgreementContext';
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import { AgreementContext } from '../../../context/AgreementContext';
import CustomerSignature from '../../Shared/CustomerSignature';
import moment from 'moment';
import { ADD_ON_PACKAGE } from '../../../constants/constants';
interface Params {
  customerId: string;
  agreementId: string;
}
export default function PromoAgrDetails({
  CoCustomerDetails: { coCustomerId },
  setItemSearchCompleted,
}) {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const history = useHistory();
  const { setPromoAgrWholeResponse, PromoAgrWholeResponse } = useContext(
    PromoAgreementContext
  );
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const classes = agreementGlobalStyles();
  const agrGeneralInfoClassName = agrGeneralInfoStyles();
  const paymentCostClassName = paymentCost();
  const AgreementDetailsContext = useContext(AgreementContext);
  const [ItemSearchValues, setItemSearchValues] = useState(
    AgreementDetailsContext
  );
  const ItemSearchVal: any = ItemSearchValues?.Itemsearch?.length > 0 ? ItemSearchValues.Itemsearch[0] : null;
  const { customerId, agreementId } = useParams<Params>();
  const defaultDropdownValue = {
    value: '',
    label: 'Select',
  };
  const initialPaymentAmoutObj = {
    Club: '',
    LDW: '',
    Tax: '',
    Rate: '',
    InitialPayment: '',
  };
  const totalPaymentAmountObj = {
    RentalCharge: '',
    TotalFees: '',
    Tax: '',
    TotalPaymentDue: '',
  };

  const selectedScheduleObj = {
    WeeklySchedule: false,
    BiWeeklySchedule: false,
    MonthlySchedule: false,
    SemiMonthlySchedule: false,
  };
  const {
    successDropdownValues,
    setsuccessDropdownValues,
    wholeAgrInfoResponse,
    setAgreementId,
    AgreementId,
  } = useContext(CustomerOrderAgreementContext);

  const {
    searchResult,
    PromoAgrItemPrice,
    agreementOptions,
    inventoryMapping,
    addedItemContext,
    setshowProgress,
    setIsAllDone,
    ItemArrayContext,
    CopyArrayContext,
    searchResultContext,
    AgreementVoidStatusContext,
    AgreementCreateStatusContext,
    setAgrStoreNum,
  } = useContext(AgreementContext);
  const PopupCompleteObj = {
    VehicleInfoComplete: false,
    ConfirmDueDateComplete: false,
    AgreementCreated: false,
    CustomerSignComplete: false,
    PrintPopupComplete: false,
    SendAgreementComplete: false,
    AutoPayComplete: false,
    SMSAutheticationComplete: false,
    InitialPaymentComplete: false,
  };
  const [PopupCompleteStatus, setPopupCompleteStatus] =
    useState(PopupCompleteObj);
  const [pageLoading, setpageLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [securityDeposit, setsecurityDeposit] = useState('0.00');
  const [endDate, setEndDate] = useState('');
  const [minendDate, setminEndDate] = useState('');
  const [maxendDate, setmaxEndDate] = useState('');
  const [scheduleLoading, setscheduleLoading] = useState(true);
  const [editDisableMode, seteditDisableMode] = useState(true);
  const [addedItemContextData, setaddedItemContext] = addedItemContext;
  const [agreementCreateStatus, setrentalagreementCreateStatus] =
    AgreementCreateStatusContext;
  const [itemArrayData, setrentalitemArrayData] = ItemArrayContext;
  const [copyArrayData, setcopyArrayData] = CopyArrayContext;
  const [valueResult, setrentalsearchResult] = searchResultContext;
  const [DeliveryChargeOptions, setDeliveryChargeOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [salesPersonOptions, setsalesPersonOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [ProcessingFeeErrorLbl, setProcessingFeeErrorLbl] = useState(false);
  const [OtherAmt, setOtherAmt] = useState('0.00');
  const [intialAmountCalc, setInitialAmountCalc] = useState('');
  const [ProcessingFeePopupOpen, setProcessingFeePopupOpen] = useState(false);
  const [optServiceValue, setoptServiceValue] = useState('');
  const [salesPersonValue, setsalesPersonValue] = useState('');
  const [processingFee, setprocessingFee] = useState('0.00');
  const [initialPaymentAmount, setinitialPaymentAmount] = useState(
    initialPaymentAmoutObj
  );
  const [GenerateDocReq, setGenerateDocReq] = useState({});
  const [totalPaymentAmount, settotalPaymentAmount] = useState(
    totalPaymentAmountObj
  );
  const [paymentPopupOpen, setpaymentPopupOpen] = useState(false);
  const [previousBtnDisabled, setpreviousBtnDisabled] = useState(false);
  const [nextBtnDisabled, setnextBtnDisabled] = useState(true);
  const [priceQuoteReq, setpriceQuoteReq] = useState<any>({});
  const [priceQuoteRes, setpriceQuoteRes]: any = useState({});
  const [createAgreementReq, setcreateAgreementReq] = useState({});
  const [somethingWentWrong, setsomethingWentWrong] = useState(false);
  const [apiFailPopupOpen, setapiFailPopupOpen] = useState(false);
  const [AgrSourceDisable, setAgrSourceDisable] = useState(false);
  const [apiFailFirstMessage, setapiFailFirstMessage] = useState('Oops');
  const [apiFailSecondMessage, setapiFailSecondMessage] = useState(
    'Unable to create agreement at this moment'
  );
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [createAgrProcessingPopup, setcreateAgrProcessingPopup] =
    useState(false);
  const [RecalculateReq, setRecalculateReq] = useState({});
  const [dropdownLoading, setdropdownLoading] = useState(true);
  const [dropdownLoaded, setdropdownLoaded] = useState(false);
  const CLUB_STATUS_ACTIVE_PENDING = ['A', 'P'];
  const API_ERROR_MESSAGE = 'Unable to fetch the data';
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [customerSignatureEnabled, setcustomerSignatureEnabled] =
    useState(false);
  const [documentGenerateCompleted, setdocumentGenerateCompleted] =
    useState(false);
  const [AgrCustomerObj, setAgrCustomerObj]: any = useState([]);
  const [CurrentDate, setcurrentdate] = useState('');
  const [MaxDate, setMaxDate] = useState('');
  const [AddingBpclubOpen, setAddingBpclubOpen] = useState(false);
  const [DecliningLdwOpen, setDecliningLdwOpen] = useState(false);
  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];
  const [VoidEnabled, setVoidEnabled] = useState(false);
  const [InitialPaymentPaid, setInitialPaymentPaid] = useState(false);
  const [IsAgreementVoidedStatus, setIsAgreementVoidedStatus] =
    AgreementVoidStatusContext;
  const [IsAgreementVoided, setIsAgreementVoided] = useState(false);
  const [deliveryCharge, setdeliveryCharge] = useState<any>('');
  const [ApiErrorMsg, setApiErrorMsg] = useState('');
  const [ErrorMsgPopupOpen, setErrorMsgPopupOpen] = useState(false);
  const pull_data = (data) => {
    setIsAgreementVoided(data);
    setIsAgreementVoidedStatus({ agreementVoidedStatus: true });
    // eslint-disable-next-line no-console
    console.log('agreementDetails void', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  useEffect(() => {
    const priceQuoteReqTemp: any = priceQuoteReq;
    const createAgreementReqTemp: any = createAgreementReq;
    console.log('agreementOptions', agreementOptions);
    console.log('searchResult[0]', searchResult);
    const dates = new Date();
    const currentdate = moment(dates).format('YYYY-MM-DD');
    const currentoneLatedate = moment().add(1, 'days').format('YYYY-MM-DD');
    const maximumdate = moment().add(3, 'months').format('YYYY-MM-DD');
    setMaxDate(maximumdate);
    setcurrentdate(currentdate);
    const dateObj = new Date(currentdate);
    dateObj.setDate(dateObj.getDate() + 30);
    const formattedDate = dateObj.toISOString().substring(0, 10);
    setEndDate(formattedDate);
    setmaxEndDate(formattedDate);
    setminEndDate(currentoneLatedate);
    setStartDate(currentdate);

    if (agreementOptions) {
      const SalesPersonArray = agreementOptions.salesPerson.map((ele: any) => {
        return { value: ele.id.toString(), label: ele.name };
      });
      SalesPersonArray.splice(0, 0, defaultDropdownValue);
      setsalesPersonOptions(SalesPersonArray);
      setdropdownLoading(false);
      seteditDisableMode(false);
    }
    if (
      searchResult != undefined &&
      agreementOptions != undefined &&
      agreementId == undefined
    ) {
      const dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 30);
      const formattedDate = dateObj.toISOString().substring(0, 10);
      console.log('PromoAgrItemPrice', PromoAgrItemPrice);
      priceQuoteReqTemp.inventoryInfo = inventoryMapping.map((ele: any) => {
        return {
          inventoryId: ele.inventoryId.inventoryId,
          inventoryPriceId: ele.inventoryId.inventoryPriceId,
        };
      });
      const PromoAgrPriceQuote = PromoAgrItemPrice?.agreementPricing;

      priceQuoteReqTemp.agreementRate = Number(
        PromoAgrPriceQuote?.agreementRate
      );
      priceQuoteReqTemp.agreementTerm = Number(
        PromoAgrPriceQuote?.agreementTerm
      );
      priceQuoteReqTemp.storeNumber =
        window.sessionStorage.getItem('storeNumber');
      priceQuoteReqTemp.customerId = Number(customerId);
      priceQuoteReqTemp.sacTotal = Number(PromoAgrPriceQuote?.cashPrice);
      priceQuoteReqTemp.securityDeposit = '';
      priceQuoteReqTemp.startDate = moment(new Date()).format('MM/DD/YYYY');
      priceQuoteReqTemp.endDate = moment(formattedDate).format('MM/DD/YYYY');
      priceQuoteReqTemp.agreementType = 'PRO';
      priceQuoteReqTemp.fees = [];
      priceQuoteReqTemp.packageName=  ItemSearchVal.packageName == ADD_ON_PACKAGE ? ADD_ON_PACKAGE : undefined ,
      priceQuoteReqTemp.totalCost = PromoAgrPriceQuote?.agreementTotal;
      console.log('priceQuoteReqTemp', priceQuoteReqTemp);
      const initialPageload = async () => {
        seteditDisableMode(true);
        setdropdownLoading(true);
        const getCOpriceQuoteResponse = await getPriceQuote(priceQuoteReqTemp);
        setpageLoading(false);
        setdropdownLoading(false);
        seteditDisableMode(false);
        setscheduleLoading(false);
        console.log('getCOpriceQuoteResponse', getCOpriceQuoteResponse);
        const getCOpriceQuoteData = getCOpriceQuoteResponse.data;
        //update
        if (getCOpriceQuoteResponse.status == 200) {
          setAgrSourceDisable(true);
          setpriceQuoteRes(getCOpriceQuoteResponse.data);
          //setPromoAgrWholeResponse(getCOpriceQuoteResponse.data);
          setdropdownLoading(false);
          seteditDisableMode(false);
          GetPriceQuoteValueBind(getCOpriceQuoteData);
          // createAgreementReqTemp.warehouseNumber = searchResult[0].wareHouseId;
          createAgreementReqTemp.fees =
            getCOpriceQuoteData.initialPaymentPromo.fees;
          createAgreementReqTemp.storeNumber =
            window.sessionStorage.getItem('storeNumber');
          if (coCustomerId) {
            createAgreementReqTemp.customer = [
              {
                customerId: Number(customerId),
                priority: 1,
              },
              {
                customerId: Number(coCustomerId),
                priority: 2,
              },
            ];
          } else {
            createAgreementReqTemp.customer = [
              {
                customerId: Number(customerId),
                priority: 1,
              },
            ];
          }
          //Dropdown formation starts here
          const DeliveryChargeArray =
            getCOpriceQuoteData?.initialPaymentPromo?.deliveryFees?.feeAmounts.map(
              (ele: any) => {
                return { value: ele, label: ele };
              }
            );
          DeliveryChargeArray?.splice(0, 0, defaultDropdownValue);
          const SalesPersonArray = agreementOptions.salesPerson.map(
            (ele: any) => {
              return { value: ele.id.toString(), label: ele.name };
            }
          );
          SalesPersonArray.splice(0, 0, defaultDropdownValue);
          setDeliveryChargeOptions(DeliveryChargeArray);
          setsalesPersonOptions(SalesPersonArray);
          // createAgreementReqTemp.hubStoreNumber =
          //   window.sessionStorage.getItem('storeNumber');
          setcreateAgreementReq(createAgreementReqTemp);
          const GenerateAgrReq: any = {};
          GenerateAgrReq.signingUrlRequired = false;
          GenerateAgrReq.signers = [];
          GenerateAgrReq.documentType = ['PROMO'];
          GenerateAgrReq.returnURL = window.location.origin;
          setGenerateDocReq(GenerateAgrReq);
        } else if (getCOpriceQuoteResponse.status == 400) {
          const ErrorMessage = getCOpriceQuoteResponse?.data?.errors[0]?.error;
          setApiErrorMsg(ErrorMessage);
          setErrorMsgPopupOpen(true);
        } else {
          setsomethingWentWrong(true);
        }
      };
      initialPageload();
    } else if (agreementId) {
      if (PromoAgrWholeResponse) {
        const agreementInfo = PromoAgrWholeResponse;
        const itemPricing: any = agreementInfo.agreementInventories.map(
          (value: any) => {
            console.log('response.itemPricing something', value);
            const individualArray: any = {
              inventoryName: value.description,
              inventoryId: value.inventoryId,
              inventoryNumber: value.inventoryNumber,
              rmsItemNumber: value.rmsItemNumber,
              weeklyRate: value.tagRate,
              weeklyTerm: value.tagTerm,
              cashPrice: value.cashPrice,
              remainingValue: value.remainingValue,
              tagTotal: value.tagTotal,
              inventoryDepreciation: value.inventoryDepreciation,
              agreementRate: value.agreementRate,
              agreementTotal: value.agreementTotal,
              packageName:
                agreementInfo.agreementInformation.packageName == null
                  ? ''
                  : agreementInfo.agreementInformation.packageName.toUpperCase() == ADD_ON_PACKAGE.toUpperCase() ? ADD_ON_PACKAGE : agreementInfo.agreementInformation.packageName,
            };

            return individualArray;
          }
        );
        setAgrStoreNum(agreementInfo?.agreementInformation?.storeNumber);
        if (
          agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1'
        ) {
          setInitialPaymentPaid(true);
        }
        if (
          agreementInfo.agreementInformation.agreementStatusRefCode === 'VOID'
        ) {
          setIsAgreementVoided(true);
          setIsAgreementVoidedStatus({ agreementVoidedStatus: true });
        }
        setsecurityDeposit(agreementInfo.initialPayment.securityDeposit);
        const totalFees =
          Number(agreementInfo.initialPayment.securityDeposit) +
          Number(agreementInfo.initialPayment.other);
        settotalPaymentAmount({
          ...totalPaymentAmount,
          RentalCharge: '0.00',
          TotalFees: String(totalFees),
          Tax: agreementInfo.initialPayment.tax,
          TotalPaymentDue: agreementInfo.initialPayment.initialPayment,
        });
        const ItemPricingContext: any = {
          itemPricing: itemPricing,
          agreementPricing: {
            tagRate: agreementInfo.agreementTotal.tagRate, //
            tagTerm: agreementInfo.agreementTotal.tagTerm, //
            tagTotal: agreementInfo.agreementTotal.tagTotal, //
            agreementRate: agreementInfo.agreementTotal.agreementRate, //
            agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
            cashPrice: agreementInfo.totalPayment.cashPrice, //
            epoAmount: agreementInfo.agreementInformation.epoAmount, //
            agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
            agreementInventoryDepreciation:
              agreementInfo.agreementTotal.agreementInventoryDepreciation, //
          },
          isPackage: false, //
          packageInformation: null, //
        };
        const agreementCreateStatus: any = {
          agreementCreatedStatus: true,
        };
        console.log('Custom Route - ItemPricingContext', ItemPricingContext);
        console.log(
          'Custom Route - agreementCreateStatus',
          agreementCreateStatus
        );
        setaddedItemContext(ItemPricingContext);
        setshowProgress(true);
        setIsAllDone(true);
        setrentalagreementCreateStatus(agreementCreateStatus);
        setrentalitemArrayData([]);
        setcopyArrayData([]);
        setrentalsearchResult([]);
        console.log('agreementInfo', agreementInfo);
        const FormatResponse: any =
          SetPendingPromoAgreementValue(agreementInfo);
        console.log('FormatResponse', FormatResponse);
        if (FormatResponse) {
          seteditDisableMode(true);
          setpageLoading(false);
          setscheduleLoading(false);
          setdropdownLoading(false);
          setAgrCustomerObj(FormatResponse.customers);
          const AgrCustomers = FormatResponse.customers;
          const Customerdata = AgrCustomers.customer.filter(
            (obj) => obj.priority == 1
          );
          console.log('Customerdata', Customerdata);
          const CustomerName =
            Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
          if (AgrCustomers.customer.length == 1) {
            const AGreementDocObj: any = GenerateDocReq;
            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId.toString(),
                customerName: CustomerName,
                phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                emailId: Customerdata[0].emailAddress,
                priority: 1,
              },
            ];

            console.log('PayloadForm', CustomerObj);
            setAgrCustomerObj(CustomerObj);
            AGreementDocObj.signers = CustomerObj;
            setGenerateDocReq(AGreementDocObj);
          } else {
            const AGreementDocObj: any = GenerateDocReq;
            const CoCustomer = AgrCustomers.coCustomer?.filter(
              (obj) => obj.priority == 2
            );
            const CoCustomerName =
              CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId.toString(),
                customerName: CustomerName,
                phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                emailId: Customerdata[0].emailAddress,
                priority: 1,
              },
              {
                customerId: CoCustomer[0].customerId.toString(),
                customerName: CoCustomerName,
                phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
                emailId: CoCustomer[0].emailAddress,
                priority: 2,
              },
            ];
            setAgrCustomerObj(CustomerObj);
            AGreementDocObj.signers = CustomerObj;
            setGenerateDocReq(AGreementDocObj);
          }
          const AgreementDocReq: any = GenerateDocReq;
          AgreementDocReq.identifier = parseInt(FormatResponse.agreementId);
          AgreementDocReq.identifierType = 'AGR';
          AgreementDocReq.signatureType = 1;
          AgreementDocReq.isRemote = false;
          AgreementDocReq.manualSignatureReasonId = null;
          if (AgrCustomers.customer.length === 1) {
            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId.toString(),
                customerName: CustomerName,
                phoneNumber:
                  Customerdata[0].phoneNumber !== null
                    ? Customerdata[0].phoneNumber
                    : '1111111111',
                emailId:
                  Customerdata[0].emailAddress !== null
                    ? Customerdata[0].emailAddress
                    : 'test@example.com',
                priority: 1,
              },
            ];
            AgreementDocReq.signers = CustomerObj;
          } else {
            const CoCustomer = AgrCustomers.coCustomer?.filter(
              (obj) => obj.priority == 2
            );
            const CoCustomerName =
              CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

            const CustomerObj = [
              {
                customerId: Customerdata[0].customerId.toString(),
                customerName: CustomerName,
                phoneNumber:
                  Customerdata[0].phoneNumber !== null
                    ? Customerdata[0].phoneNumber
                    : '1111111111',
                emailId:
                  Customerdata[0].emailAddress !== null
                    ? Customerdata[0].emailAddress
                    : 'test@example.com',
                priority: 1,
              },
              {
                customerId: CoCustomer[0].customerId.toString(),
                customerName: CoCustomerName,
                phoneNumber:
                  CoCustomer[0].phoneNumber !== null
                    ? CoCustomer[0].phoneNumber
                    : '1111111111',
                emailId:
                  CoCustomer[0].emailAddress !== null
                    ? CoCustomer[0].emailAddress
                    : 'test@example.com',
                priority: 2,
              },
            ];
            AgreementDocReq.signers = CustomerObj;
          }
          //setpreviousBtnDisabled(true);
          setdocumentGenerateCompleted(true);
          setPopupCompleteStatus({
            ...PopupCompleteStatus,
            ConfirmDueDateComplete:
              FormatResponse.popupCompleted.confirmDueDateComplete,
            AgreementCreated: FormatResponse.popupCompleted.agreementCreated,
            CustomerSignComplete:
              FormatResponse.popupCompleted.customerSignComplete,
            InitialPaymentComplete:
              FormatResponse.popupCompleted.initialPaymentComplete,
          });
          //GetPriceQuoteValueBind(FormatResponse);
          const SalesPersonArray =
            FormatResponse.agreementOption.salesPerson.map((ele: any) => {
              return { value: ele.value.toString(), label: ele.label };
            });
          SalesPersonArray.splice(0, 0, defaultDropdownValue);
          setAgrSourceDisable(true);
          setAgreementId(FormatResponse.agreementId);
          setsalesPersonOptions(SalesPersonArray);
          FormatResponse.agreementOption.salesPerson.map((el: any) => {
            setsalesPersonValue(el.value);
          });
          setnextBtnDisabled(false);
        }
      } else {
        const AgrInfoResponse = async () => {
          setItemSearchCompleted(true);
          seteditDisableMode(true);
          setpageLoading(true);
          setscheduleLoading(true);
          setdropdownLoading(true);
          const result = await getAgreementInfo(agreementId);
          if (result.status == 200) {
            const agreementInfo = result.data;
            setPromoAgrWholeResponse(agreementInfo);
            setAgrStoreNum(agreementInfo?.agreementInformation?.storeNumber);
            if (
              agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1'
            ) {
              setInitialPaymentPaid(true);
            }
            if (
              agreementInfo.agreementInformation.agreementStatusRefCode === 'VOID'
            ) {
              setIsAgreementVoided(true);
              setIsAgreementVoidedStatus({ agreementVoidedStatus: true });
            }
            const itemPricing: any = agreementInfo.agreementInventories.map(
              (value: any) => {
                console.log('response.itemPricing something', value);

                const individualArray: any = {
                  inventoryName: value.description, //
                  inventoryId: value.inventoryId, //
                  inventoryNumber: value.inventoryNumber, //
                  rmsItemNumber: value.rmsItemNumber, //
                  weeklyRate: value.tagRate, //
                  weeklyTerm: value.tagTerm, //
                  cashPrice: value.cashPrice, //
                  remainingValue: value.remainingValue, //
                  tagTotal: value.tagTotal, //
                  inventoryDepreciation: value.inventoryDepreciation, //
                  agreementRate: value.agreementRate, //
                  agreementTotal: value.agreementTotal, //
                  packageName:
                    agreementInfo.agreementInformation.packageName == null
                      ? ''
                      : agreementInfo.agreementInformation.packageName, //
                };

                return individualArray;
              }
            );
            setsecurityDeposit(agreementInfo.initialPayment.securityDeposit);
            const totalFees =
              Number(agreementInfo.initialPayment.securityDeposit) +
              Number(agreementInfo.initialPayment.other);
            settotalPaymentAmount({
              ...totalPaymentAmount,
              RentalCharge: '0.00',
              TotalFees: String(totalFees),
              Tax: agreementInfo.initialPayment.tax,
              TotalPaymentDue: agreementInfo.initialPayment.initialPayment,
            });
            const ItemPricingContext: any = {
              itemPricing: itemPricing,
              agreementPricing: {
                tagRate: agreementInfo.agreementTotal.tagRate, //
                tagTerm: agreementInfo.agreementTotal.tagTerm, //
                tagTotal: agreementInfo.agreementTotal.tagTotal, //
                agreementRate: agreementInfo.agreementTotal.agreementRate, //
                agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                cashPrice: agreementInfo.totalPayment.cashPrice, //
                epoAmount: agreementInfo.agreementInformation.epoAmount, //
                agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                agreementInventoryDepreciation:
                  agreementInfo.agreementTotal.agreementInventoryDepreciation, //
              },
              isPackage: false, //
              packageInformation: null, //
            };

            const agreementCreateStatus: any = {
              agreementCreatedStatus: true,
            };

            console.log(
              'Custom Route - ItemPricingContext',
              ItemPricingContext
            );
            console.log(
              'Custom Route - agreementCreateStatus',
              agreementCreateStatus
            );

            setaddedItemContext(ItemPricingContext);
            setshowProgress(true);
            setIsAllDone(true);
            setrentalagreementCreateStatus(agreementCreateStatus);
            setrentalitemArrayData([]);
            setcopyArrayData([]);
            setrentalsearchResult([]);
            console.log('PromoAgrWholeResponsejnkn', result.data);
            const FormatResponse: any = await SetPendingPromoAgreementValue(
              result?.data
            );
            console.log('FormatResponse', FormatResponse);
            if (FormatResponse) {
              seteditDisableMode(true);
              setpageLoading(false);
              setscheduleLoading(false);
              setdropdownLoading(false);
              setAgrCustomerObj(FormatResponse.customers);
              const AgrCustomers = FormatResponse.customers;
              const Customerdata = AgrCustomers.customer.filter(
                (obj) => obj.priority == 1
              );
              console.log('Customerdata', Customerdata);

              const CustomerName =
                Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
              if (AgrCustomers.customer.length == 1) {
                const AGreementDocObj: any = GenerateDocReq;
                const CustomerObj = [
                  {
                    customerId: Customerdata[0].customerId.toString(),
                    customerName: CustomerName,
                    phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                    emailId: Customerdata[0].emailAddress,
                    priority: 1,
                  },
                ];

                console.log('PayloadForm', CustomerObj);
                setAgrCustomerObj(CustomerObj);
                AGreementDocObj.signers = CustomerObj;
                setGenerateDocReq(AGreementDocObj);
              } else {
                const AGreementDocObj: any = GenerateDocReq;
                const CoCustomer = AgrCustomers.coCustomer?.filter(
                  (obj) => obj.priority == 2
                );
                const CoCustomerName =
                  CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
                const CustomerObj = [
                  {
                    customerId: Customerdata[0].customerId.toString(),
                    customerName: CustomerName,
                    phoneNumber: Customerdata[0]?.phoneNumbers ? Customerdata[0]?.phoneNumbers : Customerdata[0]?.phoneNumber,
                    emailId: Customerdata[0].emailAddress,
                    priority: 1,
                  },
                  {
                    customerId: CoCustomer[0].customerId.toString(),
                    customerName: CoCustomerName,
                    phoneNumber: CoCustomer[0]?.phoneNumbers ? CoCustomer[0]?.phoneNumbers : CoCustomer[0]?.phoneNumber,
                    emailId: CoCustomer[0].emailAddress,
                    priority: 2,
                  },
                ];
                setAgrCustomerObj(CustomerObj);
                AGreementDocObj.signers = CustomerObj;
                setGenerateDocReq(AGreementDocObj);
                //setcustomerSignatureEnabled(true);
              }
              const AgreementDocReq: any = GenerateDocReq;
              AgreementDocReq.identifier = parseInt(FormatResponse.agreementId);
              AgreementDocReq.identifierType = 'AGR';
              AgreementDocReq.signatureType = 1;
              AgreementDocReq.isRemote = false;
              AgreementDocReq.manualSignatureReasonId = null;
              if (AgrCustomers.customer.length === 1) {
                const CustomerObj = [
                  {
                    customerId: Customerdata[0].customerId.toString(),
                    customerName: CustomerName,
                    phoneNumber:
                      Customerdata[0].phoneNumber !== null
                        ? Customerdata[0].phoneNumber
                        : '1111111111',
                    emailId:
                      Customerdata[0].emailAddress !== null
                        ? Customerdata[0].emailAddress
                        : 'test@example.com',
                    priority: 1,
                  },
                ];
                AgreementDocReq.signers = CustomerObj;
              } else {
                const CoCustomer = AgrCustomers.coCustomer?.filter(
                  (obj) => obj.priority == 2
                );
                const CoCustomerName =
                  CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

                const CustomerObj = [
                  {
                    customerId: Customerdata[0].customerId.toString(),
                    customerName: CustomerName,
                    phoneNumber:
                      Customerdata[0].phoneNumber !== null
                        ? Customerdata[0].phoneNumber
                        : '1111111111',
                    emailId:
                      Customerdata[0].emailAddress !== null
                        ? Customerdata[0].emailAddress
                        : 'test@example.com',
                    priority: 1,
                  },
                  {
                    customerId: CoCustomer[0].customerId.toString(),
                    customerName: CoCustomerName,
                    phoneNumber:
                      CoCustomer[0].phoneNumber !== null
                        ? CoCustomer[0].phoneNumber
                        : '1111111111',
                    emailId:
                      CoCustomer[0].emailAddress !== null
                        ? CoCustomer[0].emailAddress
                        : 'test@example.com',
                    priority: 2,
                  },
                ];
                AgreementDocReq.signers = CustomerObj;
              }
              //setpreviousBtnDisabled(true);
              setdocumentGenerateCompleted(true);
              setPopupCompleteStatus({
                ...PopupCompleteStatus,
                ConfirmDueDateComplete:
                  FormatResponse.popupCompleted.confirmDueDateComplete,
                AgreementCreated:
                  FormatResponse.popupCompleted.agreementCreated,
                CustomerSignComplete:
                  FormatResponse.popupCompleted.customerSignComplete,
                InitialPaymentComplete:
                  FormatResponse.popupCompleted.initialPaymentComplete,
              });
              //GetPriceQuoteValueBind(FormatResponse);
              const SalesPersonArray =
                FormatResponse.agreementOption.salesPerson.map((ele: any) => {
                  return { value: ele.value.toString(), label: ele.label };
                });
              SalesPersonArray.splice(0, 0, defaultDropdownValue);
              setAgrSourceDisable(true);
              setAgreementId(FormatResponse.agreementId);
              setsalesPersonOptions(SalesPersonArray);
              FormatResponse.agreementOption.salesPerson.map((el: any) => {
                setsalesPersonValue(el.value);
              });
              setnextBtnDisabled(false);
            }
          } else {
            setsomethingWentWrong(true);
          }
        };
        AgrInfoResponse();
      }
    } else {
      setsomethingWentWrong(false);
    }
    setpriceQuoteReq(priceQuoteReqTemp);
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser?.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRoleRes);
          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData == undefined) {
      console.log('containerData', containerData);
      setcurrentRole('RD');
    } else {
      GettingRole();
    }
  }, []);
  useEffect(() => {
    if (AgreementId) {
      setVoidEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AgreementId]);
  useEffect(() => {
    console.log('intialAmountCalc', intialAmountCalc);
    console.log('processingFee', processingFee);
    console.log('OtherAmt', OtherAmt);

    if (intialAmountCalc && processingFee != OtherAmt) {
      const calcFees: any = Number(
        Number(intialAmountCalc) -
        Number(Number(processingFee) - Number(OtherAmt))
      );
      console.log('calcFees', calcFees);
      //const fees: any[] = []; //

      const fee: any = [
        {
          feeAmount: OtherAmt.toString(),
          feeType: 'PROC',
        },
      ];

      //fees.push(fee);
      setcreateAgreementReq({ ...createAgreementReq, fees: fee });
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        InitialPayment: calcFees.toString(),
      });
    }
  }, [OtherAmt]);
  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  const SetPendingPromoAgreementValue = (agreementInfo: any) => {
    const paymentSchedule: any = {
      weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
      weeklyTerm: parseInt(
        agreementInfo.agreementInformation.agreementWeeklyTerm
      ), //
      biweeklyRate: agreementInfo.agreementInformation.agreementBiWeeklyRate, //
      biweeklyTerm: parseInt(
        agreementInfo.agreementInformation.agreementBiWeeklyTerm
      ), //
      semimonthlyRate:
        agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
      semimonthlyTerm: parseInt(
        agreementInfo.agreementInformation.agreementSemiMonthlyTerm
      ), //
      monthlyRate: agreementInfo.agreementInformation.agreementMonthlyRate, //
      monthlyTerm: parseInt(
        agreementInfo.agreementInformation.agreementMonthlyTerm
      ), //
    };

    const agreementSchedule: any = {
      schedule: agreementInfo.agreementInformation.scheduleRefCode, //
      dueDate: agreementInfo.agreementInformation.dueDate, //
    };

    const agreementInitialPayment: any = {
      initialRate: agreementInfo.initialPayment.rate, //
      initialRateTax: '0.00', //
      ldw: agreementInfo.initialPayment.ldw, //
      ldwTax: '0.00', //
      policy: agreementInfo.initialPayment.policy, //
      policyTax: '0.00', //
      extensionAmount: agreementInfo.initialPayment.extensionAmount, //
      extensionAmountTax: '0.00', //
      totalFeesAmount: agreementInfo.initialPayment.other, //
      totalFeesAmountTax: '0.00', //
      clubAmount: agreementInfo.initialPayment.clubAmount,
      totalTax: agreementInfo.initialPayment.tax, //
      initialPayment: agreementInfo.initialPayment.initialPayment, //
    };

    const agreementTotalPayment: any = {
      initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
      regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
      regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
      finalPayment: agreementInfo.totalPayment.finalPayment, //
      totalPayments: agreementInfo.totalPayment.totalTerm, //
      sacTotal: agreementInfo.totalPayment.cashPrice, //
      epoAmount: agreementInfo.agreementInformation.epoAmount,
    };

    const employerPayschedule: any = {
      schedule: agreementInfo.agreementInformation.paySchedule, //
      daysPaid: agreementInfo.agreementInformation.payDay, //
    };

    const fees: any[] = []; //

    const fee: any = {
      feeAmount: agreementInfo.initialPayment.other,
      feeType: 'OTHER',
    };

    fees.push(fee);

    const popupCompleted: any = {
      vehicleInfoComplete: true, //
      confirmDueDateComplete: true, //
      agreementCreated: true,
      customerSignComplete:
        agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
      printPopupComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
      sendAgreementComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
      autoPayComplete: true, //
      SMSAutheticationComplete:
        agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
      initialPaymentComplete:
        agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
    };

    const customerInfo = agreementInfo.agreementCustomer.filter(
      (element) => element.priority == '1'
    );
    const coCustomerInfo = agreementInfo.agreementCustomer.filter(
      (element) => element.priority == '2'
    );

    const customers: any = {
      customer: customerInfo.map((ele) => {
        const customer: any = {
          priority: ele.priority,
          customerName: ele.firstName + ' ' + ele.lastName,
          firstName: ele.firstName,
          lastName: ele.lastName,
          customerId: ele.customerId,
          emailAddress: ele.emailId,
          ssn: null,
          gcdid: null,
          phoneNumber: ele.phoneNumber,
          phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
          isDocumentGenerated: ele.isDocumentGenerated,
        };

        return customer;
      }), //
      coCustomer: coCustomerInfo.map((ele) => {
        const coCustomer: any = {
          priority: ele.priority,
          customerName: ele.firstName + ' ' + ele.lastName,
          firstName: ele.firstName,
          lastName: ele.lastName,
          customerId: ele.customerId,
          emailAddress: ele.emailId,
          ssn: null,
          gcdid: null,
          phoneNumber: ele.phoneNumber,
          phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
          isDocumentGenerated: ele.isDocumentGenerated,
        };

        return coCustomer;
      }), //
    };

    const promoCode = agreementInfo.agreementInformation.promoCode
      ? agreementInfo.agreementInformation.promoCode
      : '';
    const promoName = agreementInfo.agreementInformation.promoName
      ? agreementInfo.agreementInformation.promoName
      : '';

    const agreementOption: any = {
      optServices: [
        {
          value: agreementInfo.agreementInformation.coverageTypeRefCode || '', //
          label: agreementInfo.agreementInformation.optServices || 'None', //
        },
      ],
      salesPerson: [
        {
          value: agreementInfo.agreementInformation.salesPersonId.toString(), //
          label: agreementInfo.agreementInformation.salesPerson, //
        },
      ],
      schedule: [
        {
          value: agreementInfo.agreementInformation.scheduleRefCode, //
          label: agreementInfo.agreementInformation.paymentSchedule, //
        },
      ],
      deliveryCharge: [
        {
          value: agreementInfo.agreementInformation.deliveryCharge,
          label: agreementInfo.agreementInformation.deliveryCharge,
        },
      ],
      promoCode: [
        {
          value: promoCode, //
          label: promoName, //
        },
      ],
    };

    const generalInformation: any = {
      agreementType: agreementInfo.agreementInformation.agreementType, //
      agreementSourceValue:
        agreementInfo.agreementInformation.agreementSourceRefCode, //
      agreementStatus: agreementInfo.agreementInformation.agreementStatus,
      deliveryChargeValue: parseInt(
        agreementInfo.agreementInformation.deliveryCharge
      ).toString(), //
      daysExtensionValue: agreementInfo.agreementInformation.daysExtension, //
      optServicesValue:
        agreementInfo.agreementInformation.coverageTypeRefCode || '', //
      promoCodeValue: promoCode, //
      policyValue:
        agreementInfo.agreementInformation.policy !== 'N/A'
          ? 'RAC Tire Plus'
          : 'None',
      salesPersonValue:
        agreementInfo.agreementInformation.salesPersonId.toString(), //
      isAutopayEnabled: agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
    };

    const agreementDetailsContext: any = {
      paymentSchedule,
      agreementSchedule,
      agreementInitialPayment,
      agreementTotalPayment,
      employerPayschedule,
      fees,
      policyApplicable:
        agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
      popupCompleted,
      agreementId: agreementInfo.agreementInformation.agreementId,
      customers,
      agreementOption,
      generalInformation,
      agreementTotal: agreementInfo.totalPayment.totalCost,
      agreementWorkflow: agreementInfo.agreementWorkflow,
    };
    console.log(
      'Custom Route - agreementDetailsContext',
      agreementDetailsContext
    );
    return agreementDetailsContext;
  };

  //Delivery Charge Onchange
  const DeliveryChargeOnchange = (value: any) => {
    setdeliveryCharge(value);
    const DeliveryChangeRequest = {
      ...priceQuoteReq,
      fees: [{ feeType: 'DELI', feeAmount: value }],
    };
    Recalculation(DeliveryChangeRequest);
  };
  //SecurityDeposit Onchange
  const SecurityDeposit = (value: any) => {
    setdeliveryCharge(value);
    const SecDepRequest = {
      ...priceQuoteReq,
      securityDeposit: value,
    };
    Recalculation(SecDepRequest);
  };
  const GetPriceQuoteValueBind = (getCOpriceQuoteData: any) => {
    if (searchResult != undefined) {
      settotalPaymentAmount({
        ...totalPaymentAmount,
        RentalCharge: getCOpriceQuoteData.totalPayment.rentalCharge,
        TotalFees: getCOpriceQuoteData.totalPayment.totalFees,
        Tax: getCOpriceQuoteData.totalPayment.totalTax,
        TotalPaymentDue: getCOpriceQuoteData.totalPayment.totalPaymentDue,
      });
    } else if (searchResult == undefined && wholeAgrInfoResponse) {
      setinitialPaymentAmount({
        ...initialPaymentAmount,
        Club: getCOpriceQuoteData.agreementInitialPayment.clubAmount,
        LDW: getCOpriceQuoteData.agreementInitialPayment.ldw,
        Tax: getCOpriceQuoteData.agreementInitialPayment.totalTax,
        Rate: getCOpriceQuoteData.agreementInitialPayment.initialRate,
        InitialPayment:
          getCOpriceQuoteData.agreementInitialPayment.initialPayment,
      });
    }
  };
  const Addsuffix = (value) => {
    const covertvalue = parseInt(value);
    if (covertvalue < 1000) {
      const FirstCheck = covertvalue % 10;
      const SecondCheck = covertvalue % 100;
      if (FirstCheck == 1 && SecondCheck != 11) {
        return covertvalue + 'st';
      }
      if (FirstCheck == 2 && SecondCheck != 12) {
        return covertvalue + 'nd';
      }
      if (FirstCheck == 3 && SecondCheck != 13) {
        return covertvalue + 'rd';
      }
      return covertvalue + 'th';
    } else {
      return value;
    }
  };
  const addArrayValue = (SourceArray: any, Keyname: string) => {
    const ArrayWithValues = SourceArray.map((ele: any) => {
      return parseFloat(ele[Keyname]);
    });
    let AdditionValue = 0;
    for (let index = 0; index < ArrayWithValues.length; index++) {
      AdditionValue = AdditionValue + ArrayWithValues[index];
    }
    return AdditionValue.toString();
  };
  function formatMoney(Money: any) {
    console.log('Money', Money);

    if (Money !== undefined && typeof Money === 'string') {
      let MoneyFormat = Money.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (MoneyFormat === '0') {
        MoneyFormat = `${MoneyFormat}.00`;
      }
      return MoneyFormat;
    }
  }

  const SalesPersonOnchange = (value: any) => {
    setsalesPersonValue(value);
    if (value != '') {
      setnextBtnDisabled(false);
    } else {
      setnextBtnDisabled(true);
    }
  };

  const PreviousBtnClick = () => {
    if (AgreementId && salesLeadId) {
      history.push({
        pathname: `/agreement/promo/itemsearch/${customerId}/${AgreementId}?coCustomerId=N&salesLeadId=${salesLeadId}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (AgreementId == undefined && salesLeadId == undefined) {
      history.push({
        pathname: `/agreement/promo/itemsearch/${customerId}?coCustomerId=N`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (AgreementId) {
      history.push({
        pathname: `/agreement/promo/itemsearch/${customerId}/${AgreementId}?coCustomerId=N`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    else if (!agreementId) {
      history.push({
        pathname: `/agreement/promo/itemsearch/${customerId}?coCustomerId=N`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const Recalculation = async (RequestObj: any) => {
    console.log('RequestObj', RequestObj);
    try {
      setpageLoading(true);
      seteditDisableMode(true);
      const getCOpriceQuoteResponse = await getPriceQuote(RequestObj);
      setpageLoading(false);
      seteditDisableMode(false);
      if (getCOpriceQuoteResponse.status == 200) {
        // eslint-disable-next-line no-debugger
        debugger;
        setpriceQuoteRes(getCOpriceQuoteResponse.data);
        setpriceQuoteReq(RequestObj);
        const getCOpriceQuoteData = getCOpriceQuoteResponse.data;
        const fees = getCOpriceQuoteData.initialPaymentPromo.fees;
        setdeliveryCharge(
          fees.length > 0
            ? getCOpriceQuoteData.initialPaymentPromo.fees[0].feeAmount
            : ''
        );
        GetPriceQuoteValueBind(getCOpriceQuoteData);
      } else if (getCOpriceQuoteResponse.status == 400) {
        const ErrorMessage = getCOpriceQuoteResponse.data.errors[0].error;
        setapiFailFirstMessage(ErrorMessage);
        setdeliveryCharge('');
        setapiFailSecondMessage('Please update and try again');
        setapiFailPopupOpen(true);
      } else {
        setpageLoading(false);
        setsomethingWentWrongOpen(true);
      }
    } catch {
      console.log('Im in Recalculation catch');
      setpageLoading(false);
      seteditDisableMode(false);
      setsomethingWentWrongOpen(true);
    }
  };

  const ErrorMsgPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={`${classes.formLabel} ${classes.m16px}`}>{ApiErrorMsg}</Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/dashboard`,
              });
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const NextBtnClick = () => {
    console.log('next btn clicked', PopupCompleteStatus);
    if (PopupCompleteStatus.InitialPaymentComplete === true) {
      history.push({
        pathname: `/agreement/promo/delivery/${customerId}/${AgreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (PopupCompleteStatus.CustomerSignComplete === true) {
      setpaymentPopupOpen(true);
    } else if (PopupCompleteStatus.AgreementCreated === false) {
      setsecondFactorEnabled(true);
    } else if (PopupCompleteStatus.AgreementCreated === true) {
      setcustomerSignatureEnabled(true);
    }
  };
  const PreliminaryPaymentCloseClick = () => {
    console.log('Close click triggered');
    setpaymentPopupOpen(false);
  };
  const PreliminaryPaymentNoBtnclick = () => {
    setpaymentPopupOpen(false);
  };
  const PreliminaryPaymentYesclick = () => {
    setpaymentPopupOpen(false);
    history.push({
      pathname: `/payment1/paymentinformation/${customerId}/${agreementId}`,
    });
  };
  const TwoFactorCancelFn = () => {
    setsecondFactorEnabled(false);
  };
  const TwoFactorCompleteFn = async (value: any) => {
    if (value) {
      console.log('optServiceValuehbb', optServiceValue);
      setsecondFactorEnabled(false);
      setcreateAgrProcessingPopup(true);
      const PromoAgr = PromoAgrItemPrice?.agreementPricing;
      const payload: any = {
        coverages: {
          isLDWApplied: false,
          isClubApplied: false,
        },
        agreementInfo: {
          agreementType: 'PRO',
          storeNumber: window.sessionStorage.getItem('storeNumber'),
          agreementSource: 'W',
          startDate: moment(startDate).format('MM/DD/YYYY'),
          endDate: moment(endDate).format('MM/DD/YYYY'),
          securityDeposit: securityDeposit,
          agreementRate: PromoAgr?.agreementRate
            ? Number(PromoAgr?.agreementRate)
            : 0.0,
          agreementTerm: PromoAgr?.agreementTerm
            ? Number(PromoAgr?.agreementTerm)
            : 0.0,
          requestAutopay: false,
          sacPrice: PromoAgr?.cashPrice ? Number(PromoAgr?.cashPrice) : 0.0,
          agreementDesc: ItemSearchVal?.itemDescription,
          salesPersonId: salesPersonValue,
        },
        // fees: priceQuoteReq?.fees ? priceQuoteReq?.fees : [],
        fees: priceQuoteReq?.fees.length > 0 ? [{
          feeType: priceQuoteReq?.fees[0].feeType,
          feeAmount: Number(priceQuoteReq?.fees[0].feeAmount)
        }] : []
      };
      if(payload.agreementInfo.agreementDesc.toUpperCase() == ADD_ON_PACKAGE){ 
        payload.agreementInfo.agreementDesc = ADD_ON_PACKAGE
      }

      // eslint-disable-next-line sonarjs/no-identical-functions
      payload.inventoryInfo = inventoryMapping.map((ele: any) => {
        return {
          inventoryId: ele.inventoryId.inventoryId,
          inventoryPriceId: ele.inventoryId.inventoryPriceId,
        };
      });
      if (coCustomerId) {
        payload.customer = [
          {
            customerId: Number(customerId),
            priority: 1,
          },
          {
            customerId: Number(coCustomerId),
            priority: 2,
          },
        ];
      } else {
        payload.customer = [
          {
            customerId: Number(customerId),
            priority: 1,
          },
        ];
      }
      const CreateAgreementResponse = await CreateAgreement(payload);
      if (CreateAgreementResponse.status == 200) {
        //setcustomerSignatureEnabled(true);
        setPopupCompleteStatus({
          ...PopupCompleteStatus,
          ConfirmDueDateComplete: true,
          AgreementCreated: true,
        });
        setIsAllDone(true);
        setAgreementId(CreateAgreementResponse.data.agreementId.toString());
        history.push({
          pathname: `/agreement/promo/details/${customerId}/${CreateAgreementResponse.data.agreementId}`,
        });
        setIsAllDone(true);
        setcreateAgrProcessingPopup(false);
        const AgreementCustomers = CreateAgreementResponse.data.customers;
        const Customerdata = AgreementCustomers.filter(
          (obj) => obj.priority == 1
        );
        const CustomerName =
          Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
        if (AgreementCustomers.length === 1) {
          const AGreementDocObj: any = GenerateDocReq;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          console.log('PayloadForm');
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        } else {
          const AGreementDocObj: any = GenerateDocReq;
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0]?.phoneNumbers ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) ? getActivePhoneNumber(Customerdata[0]?.phoneNumbers) : Customerdata[0]?.phoneNumber : Customerdata[0]?.phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber: CoCustomer[0]?.phoneNumbers ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(CoCustomer[0]?.phoneNumbers) : CoCustomer[0]?.phoneNumber : CoCustomer[0]?.phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        }
        const AgreementDocReq: any = GenerateDocReq;
        AgreementDocReq.identifier = parseInt(
          CreateAgreementResponse.data.agreementId
        );
        AgreementDocReq.identifierType = 'AGR';
        AgreementDocReq.signatureType = 1;
        AgreementDocReq.isRemote = false;
        AgreementDocReq.manualSignatureReasonId = null;
        if (AgreementCustomers.length === 1) {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        } else {
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber:
                CoCustomer[0].phoneNumber !== null
                  ? CoCustomer[0].phoneNumber
                  : '1111111111',
              emailId:
                CoCustomer[0].emailAddress !== null
                  ? CoCustomer[0].emailAddress
                  : 'test@example.com',
              priority: 2,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        }
        setpreviousBtnDisabled(true);
        const GenerateAgreementDocRes = await GenerateAgreementDoc(
          AgreementDocReq
        );
        console.log(
          'First Generate Agreement Call response',
          GenerateAgreementDocRes
        );
        setpreviousBtnDisabled(false);
        setdocumentGenerateCompleted(true);
        seteditDisableMode(true);
      } else if (CreateAgreementResponse.status == 400) {
        setcreateAgrProcessingPopup(false);
        const ErrorMessage = CreateAgreementResponse.data.errors[0].error;
        setapiFailFirstMessage(ErrorMessage);
        setapiFailSecondMessage('Please try again');
        setapiFailPopupOpen(true);
      } else {
        setcreateAgrProcessingPopup(false);
        setsomethingWentWrongOpen(true);
      }
    }
  };
  const customerSignatureCompletedFn = () => {
    setpaymentPopupOpen(true);
    setcustomerSignatureEnabled(false);
  };
  const customerSignatureCanceledFn = () => {
    setcustomerSignatureEnabled(false);
  };
  const emailcompletedFn = () => {
    setpaymentPopupOpen(true);
    setcustomerSignatureEnabled(false);
  };
  const CreateAgreementProcessingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <Typography className={classes.formLabel}>
            We are creating the agreement for you!
          </Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const ApiFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {apiFailFirstMessage}
          </Typography>
          <Typography className={classes.formLabel}>
            {' '}
            {apiFailSecondMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setapiFailPopupOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SomethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setsomethingWentWrongOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const PreliminaryPaymentPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.mb4}>
            Do you want to take initial payment?
          </Typography>
        </Grid>
        <Grid className={`${classes.pb4} ${classes.textCenter}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => PreliminaryPaymentNoBtnclick()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => PreliminaryPaymentYesclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ProcessingFeeOnBlurClick = (e: any) => {
    try {
      const InputTxt = e.target.value;
      let InputTxtArr = InputTxt.split('');
      if (InputTxtArr.includes(',') === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element !== ',');
        let TempString = '';
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        const FloatInputTxt = parseFloat(TempString);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setsecurityDeposit(ResultInputTxt);
        //setdeliveryCharge(ResultInputTxt);
        const SecDepRequest = {
          ...priceQuoteReq,
          securityDeposit: ResultInputTxt,
        };
        Recalculation(SecDepRequest);
      } else if (InputTxt === '.' || InputTxt === '') {
        // setIntPayment({ ...IntPayment, OtherAmt: '0.00' });
        setsecurityDeposit('0.00');
      } else {
        const FloatInputTxt = parseFloat(InputTxt);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setsecurityDeposit(ResultInputTxt);
        //setsecurityDeposit(ResultInputTxt);
        //setdeliveryCharge(ResultInputTxt);
        const SecDepRequest = {
          ...priceQuoteReq,
          securityDeposit: ResultInputTxt,

        };
        Recalculation(SecDepRequest);
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnClick = (e: any) => {
    try {
      const InputTxt = e.target;
      InputTxt.select();
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnchange = (value: any) => {
    try {
      const TempTxt = parseFloat(value);
      if (TempTxt <= 99999.0) {
        setsecurityDeposit(value);
      }
      if (value == undefined || value === '') {
        setsecurityDeposit('');
      }
    } catch {
      throw Error;
    }
  };
  const OverRideAmoutClick = () => {
    try {
      // const InputProcessingFeeAmt = parseFloat(processingFee);
      // const ProcessingFeeScale = parseFloat(ProcessingFeeLimit);
      const Recalculatereqobj: any = RecalculateReq;
      if (Number(processingFee) >= Number(OtherAmt)) {
        // const TempReq: any = FeeArrWOProFee;
        setProcessingFeeErrorLbl(false);
        setProcessingFeePopupOpen(false);
        const Obj = {
          feeType: 'PROC',
          feeAmount: processingFee,
        };
        // if (TempReq.filter((e) => e.feeType === 'PROC').length > 0) {
        //   const indexOfDuplicate = TempReq.findIndex(
        //     (arr) => arr.feeType === Obj.feeType
        //   );
        //   TempReq[indexOfDuplicate] = Obj;
        // } else {
        //   TempReq.push(Obj);
        // }
        //props.OtherAmtChanged(TempReq);
        Recalculatereqobj.processingFee = processingFee;
        //props.RecalculateClicked(Recalculatereqobj);
        setRecalculateReq(Recalculatereqobj);
      } else {
        setProcessingFeeErrorLbl(true);
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeePopup = () => {
    return (
      <div
        id="ProcessingFee"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid container className={`${classes.mb2} ${classes.mt2}`}>
          <Grid item md={6} className={classes.floatLeft}>
            <Typography
              variant="subtitle1"
              className={paymentCostClassName.cardLabel}
            >
              Processing Fee Amount
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Grid className={classes.floatRight}>
              <Typography
                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
              >
                $
              </Typography>
              <CurrencyInput
                id="input-example"
                name=""
                style={{ zIndex: 0, textAlign: 'right' }}
                // className={
                //   OtherDisable
                //     ? `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                //     : `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`
                // }
                className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`}
                decimalsLimit={2}
                data-testid="ProcessingFeeTxtBoxTest"
                value={OtherAmt}
                onBlur={(e) => ProcessingFeeOnBlurClick(e)}
                onClick={(e) => ProcessingFeeOnClick(e)}
                onValueChange={(value) => ProcessingFeeOnchange(value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <label
          style={{
            color: 'red',
            fontSize: 'small',
            marginLeft: '15%',

            visibility: ProcessingFeeErrorLbl === false ? 'hidden' : 'visible',
          }}
          data-testid="OverRideErrorMsgTest"
        >
          Please enter a valid processing fee amount less than $
          {`${processingFee}`}
        </label>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => OverRideAmoutClick()}
          >
            Override Amount
          </RACButton>
        </Grid>
      </div>
    );
  };
  const setTwoFactorCompleted = (value: any) => {
    if (value) {
      setProcessingFeePopupOpen(true);
    }
  };
  const setTwoFactorCancelClick = () => {
    setSecondFactorOpen(false);
  };
  const DecliningLdwOkclick = () => {
    const Recalculatereqobj: any = priceQuoteReq;
    setDecliningLdwOpen(false);
    setoptServiceValue('NONE');
    //setDropdownChanged({ ...DropdownChanged, Optservices: true });
    // Recalculatereqobj.optionalservice = {
    //   isLDWapplied: false,
    //   isClubApplied: false,
    // };
    Recalculatereqobj.isLDWApplied = false;
    Recalculatereqobj.isClubApplied = false;
    Recalculation(Recalculatereqobj);
  };
  const DecliningLdwNoclick = () => {
    setDecliningLdwOpen(false);
  };
  const AddingBenefitsPlusClubOkClick = () => {
    const Recalculatereqobj: any = priceQuoteReq;
    setAddingBpclubOpen(false);
    //setDropdownChanged({ ...DropdownChanged, Optservices: true });
    setoptServiceValue('LDWC');
    Recalculatereqobj.isLDWApplied = true;
    Recalculatereqobj.isClubApplied = true;
    Recalculation(Recalculatereqobj);
  };
  const AddingBenefitsPlusClubNoClick = () => {
    setAddingBpclubOpen(false);
  };
  const AddingBenefitsPlusClubPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            By choosing the option ‘LDW/Club’, you will be adding Benefit Plus
            club. LDW fee will be added on your current agreement and any
            existing agreements which do not have LDW.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you want to Continue?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => AddingBenefitsPlusClubNoClick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => AddingBenefitsPlusClubOkClick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DecliningLdwPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Declining LDW on this agreement will also terminate your Benefits
            Plus Coverage. You would not be able to reactivate club to cover any
            previous Paid In Full merchandise.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you still wish to terminate?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => DecliningLdwNoclick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => DecliningLdwOkclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DecliningLdwCloseClick = () => {
    setDecliningLdwOpen(false);
  };
  const AddingBpclubCloseClick = () => {
    setAddingBpclubOpen(false);
  };
  const handleStartDate = (value: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    console.log('value', value);
    setStartDate(value);
    // Parse the input date string into a Date object
    const dateObj = new Date(value);
    const currentoneLatedate = moment(dateObj)
      .add(1, 'days')
      .format('YYYY-MM-DD');
    setminEndDate(currentoneLatedate);
    dateObj.setDate(dateObj.getDate() + 30);
    const formattedDate = dateObj?.toISOString()?.substring(0, 10);
    setEndDate(formattedDate);
    setmaxEndDate(formattedDate);
  };

  return (
    <Grid>
      {secondFactorEnabled === true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => TwoFactorCancelFn()}
          setTwoFactorCompleted={(value) => TwoFactorCompleteFn(value)}
          moduleName={"Customer Order Agmt Creation"}
          currentRole={currentRole}
        />
      ) : null}
      {SecondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={"Edit SAC Fee"}
          currentRole={currentRole}
        />
      ) : null}
      {customerSignatureEnabled ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="customerOrder"
          identifier={parseInt(AgreementId)}
          customersArr={AgrCustomerObj}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={["PROMO"]}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          emailcompletedFn={() => emailcompletedFn()}
          documentGenerateCompleted={documentGenerateCompleted}
        />
      ) : null}
      {somethingWentWrong ? (
        <div
          className="Norecords"
          style={{ marginTop: "20%", marginLeft: "40%" }}
        >
          <Alerticon></Alerticon>
          Something went wrong!
        </div>
      ) : (
        <Grid>
          <RACModalCard
            isOpen={apiFailPopupOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"20px !important"}
          >
            {ApiFailPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={AddingBpclubOpen}
            closeIcon={true}
            onClose={() => AddingBpclubCloseClick()}
            maxWidth="xs"
            borderRadius={"25px !important"}
          >
            {AddingBenefitsPlusClubPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={DecliningLdwOpen}
            onClose={() => DecliningLdwCloseClick()}
            closeIcon={true}
            maxWidth="xs"
            borderRadius={"25px !important"}
          >
            {DecliningLdwPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={ProcessingFeePopupOpen}
            closeIcon={false}
            maxWidth="sm"
            borderRadius={"25px !important"}
          >
            {ProcessingFeePopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={somethingWentWrongOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"20px !important"}
          >
            {SomethingWentWrongPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={createAgrProcessingPopup}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"20px !important"}
          >
            {" "}
            {CreateAgreementProcessingPopup()}
          </RACModalCard>

          <RACModalCard
            isOpen={paymentPopupOpen}
            closeIcon={true}
            onClose={() => PreliminaryPaymentCloseClick()}
            maxWidth="xs"
            borderRadius={"20px !important"}
          >
            {PreliminaryPaymentPopup()}
          </RACModalCard>

          <RACModalCard
            isOpen={ErrorMsgPopupOpen}
            closeIcon={false}
            maxWidth="xs"
            children={ErrorMsgPopup()}
            borderRadius={"20px !important"}
          />
          <Grid className={classes.px2}>
            <Grid>
              <Grid container className={classes.mt1}>
                <Typography
                  variant="h6"
                  className={`${classes.title} ${classes.mb1}`}
                >
                  General Information
                </Typography>
              </Grid>

              <Grid container>
                <Grid item md={12} className={classes.mb4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container className={classes.mb2}>
                        <Grid
                          item
                          className={agrGeneralInfoClassName.listitemstyles}
                        >
                          <Grid
                            item
                            xs="auto"
                            className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                          >
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColor
                              }
                            >
                              Agreement Type :{" "}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                              }
                            >
                              {"Promo"}
                            </Typography>
                          </Grid>

                          {/* <Grid
                            item
                            xs="auto"
                            className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                          >
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColor
                              }
                            >
                              {'  '}Pay Schedule :{' '}
                            </Typography>
                            {paymentScheduleEnabled === true ? (
                              <Typography
                                variant="caption"
                                className={
                                  agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                                }
                              >
                                {'  '}
                                {payschedule.PayschedulePeriod !== 'Daily' &&
                                payschedule.PayschedulePeriod !== 'daily'
                                  ? payschedule.PayschedulePeriod +
                                    ' on ' +
                                    payschedule.PayscheduleDay
                                  : payschedule.PayschedulePeriod}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                className={
                                  agrGeneralInfoClassName.paymentGeneralInfoColor
                                }
                              >
                                {'  '}-
                              </Typography>
                            )}
                          </Grid> */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item sm={4} md={2}>
                          <RACDatePicker
                            value={startDate}
                            label="Start Date"
                            name="startdate"
                            disabled={editDisableMode}
                            inputProps={{
                              min: CurrentDate,
                              max: MaxDate,
                            }}
                            //classes={classes.datePickerMargin}
                            onChange={(value) => handleStartDate(value)}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACDatePicker
                            value={endDate}
                            label="End Date"
                            name="enddate"
                            disabled={editDisableMode}
                            inputProps={{
                              min: minendDate,
                              // min: moment(startDate, 'YYYY-MM-DD').add(
                              //   1,
                              //   'days'
                              // ),
                              max: maxendDate,
                            }}
                            //classes={classes.datePickerMargin}
                            onChange={(value) => setEndDate(value)}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            options={DeliveryChargeOptions}
                            defaultValue={deliveryCharge}
                            //value={deliveryCharge}
                            isDisabled={editDisableMode}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              DeliveryChargeOnchange(e.target.value);
                            }}
                            inputLabel="Delivery Charge ($)"
                            loading={dropdownLoading}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name=" Sales Person"
                            inputLabel="SalesPerson"
                            required={true}
                            options={salesPersonOptions}
                            defaultValue={salesPersonValue}
                            isDisabled={editDisableMode}
                            onChange={(e: any) =>
                              SalesPersonOnchange(e.target.value)
                            }
                            loading={dropdownLoading}
                            {...(salesPersonOptions.length == 1 &&
                              dropdownLoaded && {
                                errorMessage: API_ERROR_MESSAGE,
                              })}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.mb6}>
              <Grid item className={paymentCostClassName.paymentWidget}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Initial Payment
                    </Typography>
                    <Card className={classes.card}>
                      <CardContent className={classes.p32}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt2}`}
                        >
                          <Grid item md={6} className={classes.floatLeft}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Security Deposit
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Grid className={classes.floatRight}>
                              <Typography
                                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                              >
                                $
                              </Typography>
                              <CurrencyInput
                                id="input-example"
                                name=""
                                style={{ zIndex: 0, textAlign: "right" }}
                                // className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                                className={
                                  pageLoading || editDisableMode
                                    ? `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                                    : `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`
                                }
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                // onValueChange={(e: any) => {
                                //   const value = e?.toFixed(2);
                                //   setsecurityDeposit(value);
                                // }}
                                // onBlur={(e: any) =>
                                //   SecurityDeposit(e.target.value)
                                // }
                                onBlur={(e) => ProcessingFeeOnBlurClick(e)}
                                onClick={(e) => ProcessingFeeOnClick(e)}
                                onValueChange={(value) =>
                                  ProcessingFeeOnchange(value)
                                }
                                data-testid="ProcessingFeeTxtBoxTest"
                                value={securityDeposit}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Additional Transportation
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Installation
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Rush
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Cancellation
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Restocking
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Cleaning
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Restore
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabelPromo}
                            >
                              Product Based
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={`${classes.font18} ${classes.semiBold}`}
                            >
                              Initial Payment{" "}
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {/* {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                              >
                                ${' '}
                                {formatMoney(
                                  initialPaymentAmount.InitialPayment
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor="#FFFFFF"
                                  highlightColor="#e7e7e7"
                                />
                              </Typography>
                            )} */}
                            <Typography
                              variant="subtitle1"
                              className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                            >
                              $ {securityDeposit}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Total Payment/Total Cost
                    </Typography>

                    <Card className={paymentCostClassName.bluecard}>
                      <CardContent className={classes.p4}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              {/* Initial Payment (
                              {totalPaymentAmount.InitialPaymentTerm}) */}
                              Rental Charge
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                $ {formatMoney(totalPaymentAmount.RentalCharge)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            {/* {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                Total Fees
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueCardLabel}
                              >
                                Total Fees
                              </Typography>
                            )} */}
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              Total Fees
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                $ {formatMoney(totalPaymentAmount.TotalFees)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              Tax
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            {!pageLoading ? (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                $ {formatMoney(totalPaymentAmount.Tax)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.blueWidgetAlign}
                              >
                                <Skeleton
                                  count={1}
                                  baseColor={RACCOLOR.NORMAL_BLUE}
                                  highlightColor="#FFFFFF"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Card
                          className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade}`}
                        >
                          <Grid
                            container
                            className={paymentCostClassName.alignCenter}
                          >
                            <Grid item md={7}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.totalValue}
                              >
                                Total Payments Due
                              </Typography>
                            </Grid>
                            <Grid item md={5}>
                              {!pageLoading ? (
                                <Typography
                                  variant="subtitle1"
                                  className={
                                    paymentCostClassName.agreementTotal
                                  }
                                >
                                  ${" "}
                                  {formatMoney(
                                    totalPaymentAmount.TotalPaymentDue
                                  )}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="subtitle1"
                                  className={
                                    paymentCostClassName.agreementTotal
                                  }
                                >
                                  <Skeleton
                                    count={1}
                                    baseColor="#FFFFFF"
                                    highlightColor="#e7e7e7"
                                  />
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Card>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.fixedBottom}>
              <RACButton
                className={`${classes.mx1} ${classes.floatLeft}`}
                variant="outlined"
                color="primary"
                onClick={() => PreviousBtnClick()}
                disabled={previousBtnDisabled}
              >
                Previous
              </RACButton>
              <Grid style={{ float: "right" }}>
                <RACButton
                  className={`${classes.me2} ${classes.floatRight}`}
                  variant="contained"
                  color="primary"
                  onClick={() => NextBtnClick()}
                  disabled={
                    IsAgreementVoided ? IsAgreementVoided : nextBtnDisabled
                  }
                >
                  Next
                </RACButton>
              </Grid>
              {VoidEnabled === true &&
              IsAgreementVoided !== true &&
              InitialPaymentPaid === false ? (
                <VoidAgreement
                  AgreementId={AgreementId}
                  customerId={customerId}
                  func={pull_data}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
